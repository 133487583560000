
.mobile-slide {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: #2388FF;
    font-size: 16px;
  }

  .contents {
    white-space: pre;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }

  .footer {
    font-size: 16px;
    font-weight:700;
  }
}
