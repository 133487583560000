 @import 'Global';

$product-screen-width: 1000px;

#product-main-screen {
  .select-model {
    color: $cSecondary;
    font-size: 14px !important;
  }
  @include desktop {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: $product-screen-width;
    .split-half {
      display: flex;
      .split-image {
        width: $product-screen-width / 2;
        height: 1200px;
        // background-color: yellow ;
        padding: 30px;
        .img-box {
          display: flex;
          justify-content: center;
          padding-top: 50px;
          img {
            width: 100%;
          }
        }
      }
    }

    .split-description {
      width: $product-screen-width / 2;
      padding: 30px;
      .andromeda-package {
        font-size: 48px;
        font-weight: 700;
      }
      span {
        font-size: 18px;
        font-weight: 400px;
        margin: 2px;
      }
        .option-message {
          // position: absolute;
          font-size: 14px;
          width: 242px;
          height: 20px;
        }

      hr {
        background-color: #dee2ed;
        height: 1px;
        border: 0; 
        margin-top: 40px;
        margin-bottom: 40px;
      }
      .bold {
        font-weight: bold;
      }
      .option {
        // width: 100%;
        flex: 1;
        margin: 10px;
        padding: 20px;
        border: 1px solid;
        border-radius: 4px;
        border-color: #dee2ed;
        cursor: pointer;
        text-align: center;
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .hover-close-btn {
            position: absolute;
            height: 30px;
            width: 30px;
            right: 10px;
            opacity: 0;
            top: -40px;
          }
        }
        .col {
          align-items: center;
        }
      }
      .package-container {
        margin-top: 30px;
        display: flex;
        flex-direction: column; 
      }
      .capacity-container {
        margin-top: 30px;
        display: flex;
        flex-direction: column; 
      }
      .kimini-container {
        margin-top: 30px;
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      .description-box {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .price-box {
        background: #E9F3FF;
        padding: 30px;
        border-radius: 20px;
        margin-top: 60px;
        .annual-price {
          display: flex;
          color: gray;
          justify-content: center;
        }
        .monthly-price {
          display: flex;
          margin-top: 5%;
          color: #2388FF;
          justify-content: center;
          // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            .month-small-font{
              font-size: 24px;
              margin-top: 14px;
            }
            .month-large-font{
              font-size: 36px;
              font-weight: bold;
            }
        }
        .btn {
          // margin-top: 10px;
          font-size: 24px;
          font-weight: bold;
          border-radius: 10px;
        }
      }
    }
  }
  @include mobile {
    color: $cBlack;
    .choose {
      margin:10px;
    }
    .event-banner{
      display: block;
      position: absolute;
      margin-top: -31px;
      width: 413px;
      max-width: 100%;
      height: auto;
      cursor: pointer;
    }
    .split-description {
      padding: 30px;
      h1 {
        text-align: center;
      }
      .img-box {
        display: flex;
        justify-content: center;
        padding-bottom:50px;
        img {
          width: 100%;
        }
      }
      span {
        font-weight: 700;
      }
      .option-message {
        // position: absolute;
        margin:10px;
        font-size: 12px;
        width: 208px;
        height: 17px;
        font-weight: 0;
      }
      .light-product-bold {
        font-weight:400;
      }
      hr {
        margin:10px;
        background-color: #dee2ed;
        height: 1px;
        border: 0; 
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .bold {
        font-weight: bold;
      }
      .option {
        // width: 100%;
        flex: 1;
        margin: 10px;
        padding: 20px;
        border: 1px solid;
        border-radius: 4px;
        border-color: #dee2ed;
        cursor: pointer;
        text-align: center;
        .row {
          display: flex;
          justify-content: space-between;
        }
        .col {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .package-container {
        display: flex;
        flex-direction: column; 
      }
      .capacity-container {
        display: flex;
        flex-direction: column; 
        width: auto;
      }
      .kimini-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      .description-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .price-box {
        background: #E9F3FF;
        padding: 30px;
        border-radius: 20px;
        margin-top: 60px;
        .btn {
          margin-top: 40px;
          font-size: 24px;
          font-weight: bold;
          border-radius: 10px;
        }
        .annual-price {
          display: flex;
          color: gray;
          justify-content: center;
        }
        .monthly-price {
          display: flex;
          margin-top: 5%;
          color: #2388FF;
          justify-content: center;
          // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            .month-small-font{
              font-size: 16px;
              margin-top: 14px;
              font-weight: bold;
            }
            .month-large-font{
              font-size: 30px;
              font-weight: bold;
            }
        }
      }
    }
  }
}

#product-sub-screen {
  max-width: 1440px;
  margin: auto;
  text-align: center;
  .section {
    width: 100%;
    .download-app {
      width: 100%;
      @include desktop {
        margin-top:-160px;
        margin-bottom: 120px;
      }
      @include mobile {
        margin-top:-200px;
        margin-bottom: 80px;
      }
    }
    img {
      width: 100%;
    }
    span {
      font-size: 24px;
      font-weight: 700;
    }
    &.main3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .orgel-video {
        position: absolute;
        top: 25%;
      }
      @include mobile {
        .orgel-video {
          position: absolute;
          top: 25%;
          width: 100%;
        }
      }
    }
  }
}


#product-confirm-screen {
  @include desktop {
    width: $product-screen-width;
    margin-left: auto;
    margin-right: auto;
  }
  @include mobile {
    margin: 24px;
  }
  h2 {
    font-weight:400;
    @include mobile {
      font-size: 16px;
    }
  }
  hr {
    background-color: #dee2ed;
    height: 1px;
    border: 0; 
    margin-bottom: 24px;
  }
  .gray {
    color: $cGray
  }

  .shipping-container {
    @include desktop {
      display: flex;
      .g {
        grid-area: g;
      }
      .user-info-grid {
        flex: 1;
        display: grid;
        padding: 0px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "a b"
                              "c d "
                              "e f"
                              "g g";
        gap: 15px;
      }
    }
    @include mobile {
      .input-text{
        margin-top: 5px;
      }
    }
    .info-field {
      span {
        color: $cGray;
      }
      .red {
        color: #F67F68;
      }
      @include mobile {
        margin-top: 15px;
      }
    }
    .delivery-memo {
      color: $cGray;
    }
    .user-address-field {
      display: grid;
      width: 100%;
      grid-template-columns: 50% 40%;
      gap: 10%;
      margin-bottom: 16px;
      
      @include mobile {
        grid-template-columns: 65% 30%;
        gap: 5%;
        margin-bottom: 8px;
      }
      .postcode-btn {
        font-size: 14px;
      }
    }
    .user-address-specific {
      width: 100%;
      padding-right:24px;
      color: black;
      
    }

    .customer-info {
      @include desktop {
        width:30%;
        height: 50%;
        padding: 24px;
      }
      .change-sender{
        font-size: 18px;
        width: 100%;
        padding: 10px;
      }
      @include mobile {
        color: $cGray;
        .modify-phone {
          display: grid;
          grid-template-columns: 3fr 1fr;
          gap: 16px;
        }
      }
    }
  }
  .product-info-container {
    @include desktop {
      align-items: center;
      display: flex;
      padding-top: 20px;
      padding-bottom: 30px;
    }
    .info-image {
      width: 100px;
      height: 100px;
      margin-right: 30px;
      border-radius: 20px;
      background-color: blue;

      @include mobile {
        width: 58px;
        height:58px;
        border-radius: 8px;
      }
    }
    .info-detail {
      @include desktop {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 18px;
      }
      @include mobile {
        display: flex;
        font-size: 14px;
      }
    }
    .info-price {
      display: flex;
      @include desktop {
        flex-direction: column;
        align-items: flex-end;
        font-weight: 700;
        font-size: 22px;
      }
       .annual-price{
        color: grey;
        font-size: smaller;
       }   
      @include mobile {
        justify-content: space-between;
        font-size: 14px;
        .total-price {
          // font-weight:700;
          color: gray;
          font-size: 11px;
        }
      }
    }
    .installment-price {
      float: right;
      font-size: 14px;
      font-weight:700;
    }
  }
  .shipping-route {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color:$cGray;
    margin-bottom: 40px;
    .check-shipping-route {
      color: $cBlack;
    }
  }
  .highlight-box {
    background-color: #F9F9F9;;
    border-radius: 20px;
  }
  .price-box {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      margin: 10px;
    }
  }
  .submit-box {
    padding: 20px;
    display: flex;
    justify-content: center;
    .submit-btn {
      width: 300px;
      font-size: 24px;
      font-weight: 700;
      border-radius: 10px;
    }
  }
}