#travel-screen {
  margin: auto;
}
#travel-screen .section {
  max-width: 1920px;
  text-align: center;
}
#travel-screen img {
  max-width: 1080px;
  width: 100%;
}
@media (min-width: 768px) {
  #travel-screen .main1 {
    background: url("/public/images/mainPage/travelMain1.svg") no-repeat;
    background-position: center;
    height: 660px;
  }
}
@media (max-width: 479px) {
  #travel-screen .main1 {
    margin-top: -30px;
  }
}
@media (min-width: 768px) {
  #travel-screen .main2 {
    background-color: #A1C8FF;
  }
  #travel-screen .main2 img {
    margin-top: 46px;
    margin-bottom: 120px;
  }
}
@media (max-width: 479px) {
  #travel-screen .main2 {
    margin-top: 52px;
    margin-bottom: 16px;
  }
  #travel-screen .main2 img {
    width: 80%;
  }
}
#travel-screen .main3 {
  background-color: #DBB7FF;
}
@media (max-width: 479px) {
  #travel-screen .main3 {
    background: url("/public/images/mainPageMobile/travelMain3_m.svg") no-repeat;
    background-position: center;
  }
  #travel-screen .main3 .travel-textbook {
    height: 505px;
    margin-bottom: 38px;
  }
  #travel-screen .main3 .travel-textbook img {
    margin-top: 50px;
    width: 321px;
    height: 341px;
  }
}
#travel-screen .main4 {
  margin: auto;
  height: 1126px;
  max-width: 1080px;
  background: url("/public/images/mainPage/travelMain4.svg") no-repeat;
  background-position: center;
  padding-top: 116px;
}
#travel-screen .main4 .travel-textbook {
  max-width: 900px;
  padding-top: 250px;
}
#travel-screen .main4 .travel-textbook img {
  width: 714px;
  height: 665px;
}
#travel-screen .main4 .travel-textbook .swiper-button-next {
  margin-top: 150px;
}
#travel-screen .main4 .travel-textbook .swiper-button-prev {
  margin-top: 150px;
}
#travel-screen .main5 {
  background-color: #E4E4E4;
}
#travel-screen .main6 {
  background-color: #000000;
}
#travel-screen .swiper-button-next {
  width: 64px;
  height: 64px;
  background: url("/public/images/mainPage/swiperRight.svg") no-repeat;
}
#travel-screen .swiper-button-next:hover {
  background: url("/public/images/mainPage/swiperRightHover.svg") no-repeat;
}
#travel-screen .swiper-button-prev {
  width: 64px;
  height: 64px;
  background: url("/public/images/mainPage/swiperLeft.svg") no-repeat;
}
#travel-screen .swiper-button-prev:hover {
  background: url("/public/images/mainPage/swiperLeftHover.svg") no-repeat;
}
#travel-screen .swiper-button-next::after,
#travel-screen .swiper-button-prev::after {
  display: none;
}/*# sourceMappingURL=Travel.css.map */