#auth-kakao .signup-sample {
  margin-bottom: 6px;
}
#auth-kakao .auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#auth-kakao .auth-options h1 {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  #auth-kakao .auth-options {
    margin-top: 100px;
    width: 452px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  #auth-kakao .auth-options {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
  }
}
#auth-kakao .auth-options .auth-aka-logo {
  cursor: pointer;
  display: block;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 479px) {
  #auth-kakao .auth-options .auth-aka-logo {
    margin-top: 50px;
  }
}
#auth-kakao .auth-options .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #auth-kakao .auth-options .auth-option-field {
    width: 328px;
  }
}
#auth-kakao .kakao-header {
  font-weight: 400;
}
#auth-kakao .kakao-header span {
  font-weight: 700;
}
@media (min-width: 768px) {
  #auth-kakao .kakao-header {
    font-size: 30px;
    margin-bottom: 100px;
  }
}
@media (max-width: 479px) {
  #auth-kakao .kakao-header {
    font-size: 18px;
    margin-bottom: 80px;
  }
}
@media (min-width: 768px) {
  #auth-kakao .login-button {
    width: 452px;
    height: 64px;
    font-size: 22px;
    margin-bottom: 24px;
  }
}
@media (max-width: 479px) {
  #auth-kakao .login-button {
    width: 328px;
    height: 48px;
    font-size: 16px;
    margin-bottom: 16px;
  }
}
#auth-kakao .login-button img {
  position: absolute;
  left: 20px;
}
@media (max-width: 479px) {
  #auth-kakao .login-button img {
    width: 19px;
    height: 18px;
  }
}
#auth-kakao .email {
  border: 1px solid #C0C0C0;
}
#auth-kakao .email:hover {
  background: #F5F5F5;
}