@import 'Auth';

#auth-resign {
  @include auth-outline;
  @include mobile {
    margin: auto;
    margin: 24px;
  }

  .resign-header {
    font-size: 24px;
    font-weight:700;
    margin-bottom: 24px;
  }
  .resign-content {
    text-align: center;
    
  }
  .resign-field {
    margin-top: 24px;
    margin-bottom: 8px;
    width:100%;
  }
  .resign-button {
    display: flex;
    justify-content: space-between;
    width:100%;
    @include mobile {
      gap: 24px;
    }
    .resign-button-divide {
      flex-direction: row;
      margin-top: 40px;
      width: 210px;
      height: 64px;
      font-weight: 700;
      font-size: 24px;
      border-radius: 8px;
    }
    .cancel-resign {
      background-color: #FFFFFF;
      border: 1px solid #2388FF;
      color: #2388FF;
    }
  }

}