@import 'Global';
@import 'Auth';

#auth-login {
  @include auth-outline;

  .auth-button {
    @include mobile {
      width: 328px;
    }
    width: 452px;
    height: 64px;
    color: #FFFFFF;
    font-size: 24px;
    border-radius: 8px;
  }

  .sub-button {
    margin-top:16px;
    justify-content: center;
    display: flex;

    .join-and-find {
      font-size: 18px;
      font-weight: normal;
      color: $cDarkGray;
      border-color : $cDarkGray;
    }
  }

  .sign-up-text {
    font-size: 16px;
    color: $cDarkGray;
    margin-bottom: 12px;
  }

  .sign-up {
    color: $cBlack;
    border: 1px solid #C0C0C0;

    @include desktop {
      width: 452px;
      height: 64px;
      margin-bottom: 100px;
      font-size: 22px;
      border-radius: 8px;
    }
    @include mobile {
      margin-bottom: 80px;
    }

  }  

  .sign-up:hover {
    background: #F5F5F5;
  }
}