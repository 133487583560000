#product-main-screen .select-model {
  color: #2388ff;
  font-size: 14px !important;
}
@media (min-width: 821px) {
  #product-main-screen {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1000px;
  }
  #product-main-screen .split-half {
    display: flex;
  }
  #product-main-screen .split-half .split-image {
    width: 500px;
    height: 1200px;
    padding: 30px;
  }
  #product-main-screen .split-half .split-image .img-box {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
  #product-main-screen .split-half .split-image .img-box img {
    width: 100%;
  }
  #product-main-screen .split-description {
    width: 500px;
    padding: 30px;
  }
  #product-main-screen .split-description .andromeda-package {
    font-size: 48px;
    font-weight: 700;
  }
  #product-main-screen .split-description span {
    font-size: 18px;
    font-weight: 400px;
    margin: 2px;
  }
  #product-main-screen .split-description .option-message {
    font-size: 14px;
    width: 242px;
    height: 20px;
  }
  #product-main-screen .split-description hr {
    background-color: #dee2ed;
    height: 1px;
    border: 0;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #product-main-screen .split-description .bold {
    font-weight: bold;
  }
  #product-main-screen .split-description .option {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border: 1px solid;
    border-radius: 4px;
    border-color: #dee2ed;
    cursor: pointer;
    text-align: center;
  }
  #product-main-screen .split-description .option .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #product-main-screen .split-description .option .row .hover-close-btn {
    position: absolute;
    height: 30px;
    width: 30px;
    right: 10px;
    opacity: 0;
    top: -40px;
  }
  #product-main-screen .split-description .option .col {
    align-items: center;
  }
  #product-main-screen .split-description .package-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  #product-main-screen .split-description .capacity-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  #product-main-screen .split-description .kimini-container {
    margin-top: 30px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  #product-main-screen .split-description .description-box {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #product-main-screen .split-description .price-box {
    background: #E9F3FF;
    padding: 30px;
    border-radius: 20px;
    margin-top: 60px;
  }
  #product-main-screen .split-description .price-box .annual-price {
    display: flex;
    color: gray;
    justify-content: center;
  }
  #product-main-screen .split-description .price-box .monthly-price {
    display: flex;
    margin-top: 5%;
    color: #2388FF;
    justify-content: center;
  }
  #product-main-screen .split-description .price-box .monthly-price .month-small-font {
    font-size: 24px;
    margin-top: 14px;
  }
  #product-main-screen .split-description .price-box .monthly-price .month-large-font {
    font-size: 36px;
    font-weight: bold;
  }
  #product-main-screen .split-description .price-box .btn {
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
  }
}
@media (max-width: 479px) {
  #product-main-screen {
    color: #28292B;
  }
  #product-main-screen .choose {
    margin: 10px;
  }
  #product-main-screen .event-banner {
    display: block;
    position: absolute;
    margin-top: -31px;
    width: 413px;
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }
  #product-main-screen .split-description {
    padding: 30px;
  }
  #product-main-screen .split-description h1 {
    text-align: center;
  }
  #product-main-screen .split-description .img-box {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
  }
  #product-main-screen .split-description .img-box img {
    width: 100%;
  }
  #product-main-screen .split-description span {
    font-weight: 700;
  }
  #product-main-screen .split-description .option-message {
    margin: 10px;
    font-size: 12px;
    width: 208px;
    height: 17px;
    font-weight: 0;
  }
  #product-main-screen .split-description .light-product-bold {
    font-weight: 400;
  }
  #product-main-screen .split-description hr {
    margin: 10px;
    background-color: #dee2ed;
    height: 1px;
    border: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #product-main-screen .split-description .bold {
    font-weight: bold;
  }
  #product-main-screen .split-description .option {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border: 1px solid;
    border-radius: 4px;
    border-color: #dee2ed;
    cursor: pointer;
    text-align: center;
  }
  #product-main-screen .split-description .option .row {
    display: flex;
    justify-content: space-between;
  }
  #product-main-screen .split-description .option .col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #product-main-screen .split-description .package-container {
    display: flex;
    flex-direction: column;
  }
  #product-main-screen .split-description .capacity-container {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  #product-main-screen .split-description .kimini-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  #product-main-screen .split-description .description-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #product-main-screen .split-description .price-box {
    background: #E9F3FF;
    padding: 30px;
    border-radius: 20px;
    margin-top: 60px;
  }
  #product-main-screen .split-description .price-box .btn {
    margin-top: 40px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
  }
  #product-main-screen .split-description .price-box .annual-price {
    display: flex;
    color: gray;
    justify-content: center;
  }
  #product-main-screen .split-description .price-box .monthly-price {
    display: flex;
    margin-top: 5%;
    color: #2388FF;
    justify-content: center;
  }
  #product-main-screen .split-description .price-box .monthly-price .month-small-font {
    font-size: 16px;
    margin-top: 14px;
    font-weight: bold;
  }
  #product-main-screen .split-description .price-box .monthly-price .month-large-font {
    font-size: 30px;
    font-weight: bold;
  }
}

#product-sub-screen {
  max-width: 1440px;
  margin: auto;
  text-align: center;
}
#product-sub-screen .section {
  width: 100%;
}
#product-sub-screen .section .download-app {
  width: 100%;
}
@media (min-width: 821px) {
  #product-sub-screen .section .download-app {
    margin-top: -160px;
    margin-bottom: 120px;
  }
}
@media (max-width: 479px) {
  #product-sub-screen .section .download-app {
    margin-top: -200px;
    margin-bottom: 80px;
  }
}
#product-sub-screen .section img {
  width: 100%;
}
#product-sub-screen .section span {
  font-size: 24px;
  font-weight: 700;
}
#product-sub-screen .section.main3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#product-sub-screen .section.main3 .orgel-video {
  position: absolute;
  top: 25%;
}
@media (max-width: 479px) {
  #product-sub-screen .section.main3 .orgel-video {
    position: absolute;
    top: 25%;
    width: 100%;
  }
}

@media (min-width: 821px) {
  #product-confirm-screen {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 479px) {
  #product-confirm-screen {
    margin: 24px;
  }
}
#product-confirm-screen h2 {
  font-weight: 400;
}
@media (max-width: 479px) {
  #product-confirm-screen h2 {
    font-size: 16px;
  }
}
#product-confirm-screen hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin-bottom: 24px;
}
#product-confirm-screen .gray {
  color: #979797;
}
@media (min-width: 821px) {
  #product-confirm-screen .shipping-container {
    display: flex;
  }
  #product-confirm-screen .shipping-container .g {
    grid-area: g;
  }
  #product-confirm-screen .shipping-container .user-info-grid {
    flex: 1;
    display: grid;
    padding: 0px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "a b" "c d " "e f" "g g";
    gap: 15px;
  }
}
@media (max-width: 479px) {
  #product-confirm-screen .shipping-container .input-text {
    margin-top: 5px;
  }
}
#product-confirm-screen .shipping-container .info-field span {
  color: #979797;
}
#product-confirm-screen .shipping-container .info-field .red {
  color: #F67F68;
}
@media (max-width: 479px) {
  #product-confirm-screen .shipping-container .info-field {
    margin-top: 15px;
  }
}
#product-confirm-screen .shipping-container .delivery-memo {
  color: #979797;
}
#product-confirm-screen .shipping-container .user-address-field {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 40%;
  gap: 10%;
  margin-bottom: 16px;
}
@media (max-width: 479px) {
  #product-confirm-screen .shipping-container .user-address-field {
    grid-template-columns: 65% 30%;
    gap: 5%;
    margin-bottom: 8px;
  }
}
#product-confirm-screen .shipping-container .user-address-field .postcode-btn {
  font-size: 14px;
}
#product-confirm-screen .shipping-container .user-address-specific {
  width: 100%;
  padding-right: 24px;
  color: black;
}
@media (min-width: 821px) {
  #product-confirm-screen .shipping-container .customer-info {
    width: 30%;
    height: 50%;
    padding: 24px;
  }
}
#product-confirm-screen .shipping-container .customer-info .change-sender {
  font-size: 18px;
  width: 100%;
  padding: 10px;
}
@media (max-width: 479px) {
  #product-confirm-screen .shipping-container .customer-info {
    color: #979797;
  }
  #product-confirm-screen .shipping-container .customer-info .modify-phone {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 16px;
  }
}
@media (min-width: 821px) {
  #product-confirm-screen .product-info-container {
    align-items: center;
    display: flex;
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
#product-confirm-screen .product-info-container .info-image {
  width: 100px;
  height: 100px;
  margin-right: 30px;
  border-radius: 20px;
  background-color: blue;
}
@media (max-width: 479px) {
  #product-confirm-screen .product-info-container .info-image {
    width: 58px;
    height: 58px;
    border-radius: 8px;
  }
}
@media (min-width: 821px) {
  #product-confirm-screen .product-info-container .info-detail {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 18px;
  }
}
@media (max-width: 479px) {
  #product-confirm-screen .product-info-container .info-detail {
    display: flex;
    font-size: 14px;
  }
}
#product-confirm-screen .product-info-container .info-price {
  display: flex;
}
@media (min-width: 821px) {
  #product-confirm-screen .product-info-container .info-price {
    flex-direction: column;
    align-items: flex-end;
    font-weight: 700;
    font-size: 22px;
  }
}
#product-confirm-screen .product-info-container .info-price .annual-price {
  color: grey;
  font-size: smaller;
}
@media (max-width: 479px) {
  #product-confirm-screen .product-info-container .info-price {
    justify-content: space-between;
    font-size: 14px;
  }
  #product-confirm-screen .product-info-container .info-price .total-price {
    color: gray;
    font-size: 11px;
  }
}
#product-confirm-screen .product-info-container .installment-price {
  float: right;
  font-size: 14px;
  font-weight: 700;
}
#product-confirm-screen .shipping-route {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #979797;
  margin-bottom: 40px;
}
#product-confirm-screen .shipping-route .check-shipping-route {
  color: #28292B;
}
#product-confirm-screen .highlight-box {
  background-color: #F9F9F9;
  border-radius: 20px;
}
#product-confirm-screen .price-box {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#product-confirm-screen .price-box h3 {
  margin: 10px;
}
#product-confirm-screen .submit-box {
  padding: 20px;
  display: flex;
  justify-content: center;
}
#product-confirm-screen .submit-box .submit-btn {
  width: 300px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 10px;
}/*# sourceMappingURL=Product.css.map */