@import 'Global';
@import 'Auth';

#auth-kakao {
  .signup-sample{
    margin-bottom: 6px;
  }
  @include auth-outline;

  .kakao-header {
    font-weight: 400;
    span {
      font-weight: 700;
    }

    @include desktop {
      font-size: 30px;
      margin-bottom: 100px;
    }
    
    @include mobile {
      font-size: 18px;
      margin-bottom: 80px;
    }
  }

  .login-button {
    @include desktop {
      width: 452px;
      height: 64px;
      font-size: 22px;
      margin-bottom: 24px;
    }
    @include mobile {
      width: 328px;
      height: 48px;
      font-size: 16px;
      margin-bottom: 16px;
    }
    img {
      position: absolute;
      left: 20px;
      @include mobile {
        width: 19px;
        height: 18px;
      }
    }
  }
  .email {
    border: 1px solid #C0C0C0;
  }

  .email:hover {
    background: #F5F5F5;
  }

}