@import 'Global';

@mixin toss-screen {
  width: 600px;
  margin: auto;
  text-align: center;
  @include mobile {
    width: 200px;
  }
}

#toss-loading-screen {
  margin: auto;
  text-align: center;
}
#toss-result-screen{
  @include toss-screen;
  .ipad-terms {
    color: #F67F68;
  }
  .action-container {
    display: inline;
    .go-btn {
      border-radius: 8px;
      font-size: 24px;
      width: 452px;
      height: 64px;
      color: white;
    }
  }
}

#toss-fail-screen {
  @include toss-screen;
}