@media (min-width: 821px) {
  #event-main {
    max-width: 1080px;
    margin: auto;
  }
  #event-main .title {
    display: flex;
    justify-content: space-between;
  }
  #event-main .event-list {
    height: 358px;
    margin-bottom: 200px;
    cursor: pointer;
  }
}
@media (max-width: 479px) {
  #event-main {
    max-width: 327px;
    margin: auto;
  }
  #event-main h2 {
    margin-top: -120px;
    text-align: center;
  }
}

#hawaii-event {
  margin: auto;
  max-width: 100%;
  text-align: center;
}
#hawaii-event .hawaii-header {
  max-width: 1080px;
  margin: auto;
  text-align: center;
}
#hawaii-event .hawaii-header .sns-row {
  display: flex;
  height: 24px;
  margin-top: 6px;
}
@media (min-width: 821px) {
  #hawaii-event .hawaii-header .sns-row {
    float: right;
  }
}
@media (max-width: 479px) {
  #hawaii-event .hawaii-header .sns-row {
    justify-content: center;
  }
}
#hawaii-event .hawaii-header .sns-row img {
  justify-content: space-between;
}
#hawaii-event .hawaii-header .sns-row .facebook-icon {
  margin-left: 18px;
  margin-right: 25px;
}
#hawaii-event .hawaii-header .sns-row .facebook-icon:hover {
  content: url("/public/images/misc/facebookIconHover.png");
}
#hawaii-event .hawaii-header .sns-row .twitter-icon {
  margin-left: 18px;
  margin-right: 25px;
}
#hawaii-event .hawaii-header .sns-row .twitter-icon:hover {
  content: url("/public/images/misc/twitterIconHover.png");
}
#hawaii-event .hawaii-header .sns-row .link-icon:hover {
  content: url("/public/images/misc/linkIconHover.png");
}
#hawaii-event .hawaii-header span span {
  color: #2388ff;
}
@media (max-width: 479px) {
  #hawaii-event .hawaii-header .title {
    margin-top: 60px;
    font-size: 18px;
    font-weight: 700;
  }
  #hawaii-event .hawaii-header span span {
    color: #2388ff;
  }
}
@media (min-width: 821px) {
  #hawaii-event .hawaii-header .title {
    margin-top: 100px;
    margin-left: 148px;
    font-weight: 700;
    font-size: 24px;
  }
}
#hawaii-event .event-bar-fixed {
  display: flex;
  position: fixed;
  background-color: white;
  justify-content: space-around;
  top: 0px;
  height: 113px;
  width: 100%;
  z-index: 501;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar-fixed {
    width: 100%;
    justify-content: space-between;
    margin-left: 0px;
    margin-top: 80px;
    height: 64px;
  }
}
#hawaii-event .event-bar-fixed .item-holder {
  margin-left: 0%;
  margin-bottom: 5px;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar-fixed .item-holder {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 0px;
    margin-bottom: 0px;
    margin-top: 6px;
  }
}
#hawaii-event .event-bar-fixed .item-holder .item {
  display: inline-block;
  width: 360px;
  height: 100px;
  cursor: pointer;
  margin-top: 12px;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar-fixed .item-holder .item {
    margin-top: 0px;
  }
}
#hawaii-event .event-bar-fixed .item-holder .item .item-text {
  font-size: 20px;
  color: #28292B;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar-fixed .item-holder .item .item-text {
    font-size: 14px;
    font-weight: 500;
    height: 64px;
  }
}
#hawaii-event .event-bar-fixed .item-holder .item .item-text-fixed {
  display: block;
  content: "";
  font-size: 20px;
  color: #28292B;
  padding-bottom: 22px;
  border-bottom: solid 5px #28292B;
  transition: transform 250ms ease-in-out;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar-fixed .item-holder .item .item-text-fixed {
    font-size: 14px;
    font-weight: 500;
    height: auto;
    padding-bottom: 13px;
    color: #28292B;
    border-bottom: solid 5px #28292B;
    transition: transform 250ms ease-in-out;
  }
}
#hawaii-event .event-bar-fixed .item-holder .item .item-text::after {
  display: block;
  content: "";
  padding-bottom: 22px;
  border-bottom: solid 5px #28292B;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar-fixed .item-holder .item .item-text::after {
    padding-bottom: 5px;
  }
}
#hawaii-event .event-bar-fixed .item-holder .item .item-text:hover::after {
  transform: scaleX(1);
}
#hawaii-event .event-bar {
  display: flex;
  justify-content: space-around;
  background-color: white;
  height: 100px;
  position: relative;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar {
    width: 100%;
    height: 64px;
  }
}
#hawaii-event .event-bar .item-holder {
  margin-left: 0%;
  margin-bottom: 5px;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar .item-holder {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 0px;
    margin-bottom: 0px;
    margin-top: 6px;
  }
}
#hawaii-event .event-bar .item-holder .item {
  display: inline-block;
  width: 360px;
  cursor: pointer;
}
#hawaii-event .event-bar .item-holder .item .item-text {
  font-size: 20px;
  color: #28292B;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar .item-holder .item .item-text {
    font-size: 14px;
    font-weight: 500;
  }
}
#hawaii-event .event-bar .item-holder .item .item-text::after {
  display: block;
  content: "";
  padding-bottom: 22px;
  border-bottom: solid 5px #28292B;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
@media (max-width: 479px) {
  #hawaii-event .event-bar .item-holder .item .item-text::after {
    padding-bottom: 5px;
  }
}
#hawaii-event .event-bar .item-holder .item .item-text:hover::after {
  transform: scaleX(1);
}
#hawaii-event .hawaii-main {
  height: auto;
  margin-bottom: -10px;
}
@media (max-width: 479px) {
  #hawaii-event .hawaii-main {
    width: 100%;
  }
  #hawaii-event .hawaii-main.orgel-download {
    margin-top: 80px;
    width: 75%;
  }
  #hawaii-event .hawaii-main.orgel-download-ios {
    width: 35%;
    margin-top: 19px;
    margin-bottom: 50px;
  }
}
#hawaii-event .hawaii-main.orgel-download {
  position: relative;
}
#hawaii-event .hawaii-main.orgel-download .orgel-download-step {
  max-width: 100%;
}
#hawaii-event .hawaii-main.orgel-download .orgel-ios-download {
  position: absolute;
  right: 45%;
  top: 43%;
  width: 10%;
  z-index: 1;
}
#hawaii-event .hawaii-apply {
  color: #FFFFFF;
  text-align: center;
  height: 608px;
}
@media (min-width: 821px) {
  #hawaii-event .hawaii-apply {
    background: url("/public/images/mainPage/eventMain5.png");
  }
  #hawaii-event .hawaii-apply h1 {
    padding-top: 160px;
    font-weight: 900;
    font-size: 3em;
  }
  #hawaii-event .hawaii-apply span {
    font-size: 1.5em;
  }
  #hawaii-event .hawaii-apply .apply-button {
    font-size: 24px;
    font-weight: 700;
    border-radius: 8px;
    width: 424px;
    height: 64px;
    margin-top: 27px;
  }
}
@media (max-width: 479px) {
  #hawaii-event .hawaii-apply {
    background: url("/public/images/mainPageMobile/eventMain6_m.png");
    background-size: cover;
  }
  #hawaii-event .hawaii-apply h1 {
    font-size: 2.25em;
    font-weight: 900;
    padding-top: 80px;
  }
  #hawaii-event .hawaii-apply span {
    font-size: 1.125em;
  }
  #hawaii-event .hawaii-apply .apply-button {
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    width: 328px;
    height: 48px;
    margin-top: 27px;
  }
}
#hawaii-event .hawaii-apply .form-button-text {
  font-size: 1.125em;
  color: #000000;
  margin-top: 94px;
}
#hawaii-event .hawaii-qr {
  margin-top: -25px;
  background: #434D6B;
}
@media (min-width: 821px) {
  #hawaii-event .hawaii-qr .plate-button {
    font-size: 24px;
    font-weight: 700;
    border-radius: 8px;
    width: 424px;
    height: 64px;
    margin-top: 27px;
  }
}
@media (max-width: 479px) {
  #hawaii-event .hawaii-qr .plate-button {
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    width: 328px;
    height: 48px;
    margin-top: 27px;
    margin-bottom: 60px;
  }
}
#hawaii-event .hawaii-qr a {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
#hawaii-event .hawaii-qr .musio-qr {
  position: relative;
}
#hawaii-event .hawaii-qr .musio-qr .is-not-login {
  position: absolute;
  text-decoration: underline;
  color: #7F8184;
}
@media (min-width: 821px) {
  #hawaii-event .hawaii-qr .musio-qr .is-not-login {
    transform: translate(-365px);
    top: 45%;
  }
}
@media (max-width: 479px) {
  #hawaii-event .hawaii-qr .musio-qr .is-not-login {
    transform: translate(-180px);
    top: 35%;
  }
}
#hawaii-event .hawaii-qr .musio-qr .is-login {
  position: absolute;
  text-decoration: underline;
  color: #7F8184;
}
@media (min-width: 821px) {
  #hawaii-event .hawaii-qr .musio-qr .is-login {
    width: 180px;
    height: 180px;
    transform: translate(-400px);
    top: 28%;
  }
}
@media (max-width: 479px) {
  #hawaii-event .hawaii-qr .musio-qr .is-login {
    width: 124px;
    height: 124px;
    transform: translate(-218px);
    top: 26%;
  }
}
#hawaii-event .hawaii-notice {
  margin: auto;
}
@media (min-width: 821px) {
  #hawaii-event .hawaii-notice {
    width: 1000px;
  }
}
#hawaii-event .hawaii-main1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: auto;
}
#hawaii-event .limit-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}
#hawaii-event .event-ref {
  position: relative;
  top: -100px;
}
@media (max-width: 479px) {
  #hawaii-event .event-ref {
    position: relative;
    top: -104px;
  }
}
#hawaii-event .back-button {
  width: 452px;
  height: 64px;
  font-size: 24px;
  font-weight: 700;
  background-color: #FFFFFF;
  border: 1px solid #2388ff;
  color: #2388ff;
}
#hawaii-event .back-button-green1 {
  position: relative;
  bottom: 101px;
  left: 0%;
  width: 424px;
  height: 80px;
  font-size: 24px;
  font-weight: 700;
  background-color: #00FB55;
  color: #28292B;
}
#hawaii-event .back-button-red1 {
  position: relative;
  bottom: 101px;
  left: 0%;
  width: 424px;
  height: 80px;
  font-size: 24px;
  font-weight: 700;
  background-color: #FF1D38;
  color: #FFFFFF;
}
#hawaii-event .back-button-black2 {
  position: relative;
  bottom: 101px;
  left: 0%;
  width: 424px;
  height: 80px;
  font-size: 24px;
  font-weight: 700;
  background-color: #000000;
  color: #FFFFFF;
}
@media (max-width: 479px) {
  #hawaii-event .back-button {
    width: 156px;
    height: 48px;
    font-size: 16px;
    font-weight: 700;
    background-color: #FFFFFF;
    border: 1px solid #2388ff;
    color: #2388ff;
  }
  #hawaii-event .back-button-green1 {
    position: absolute;
    width: 328px;
    height: 48px;
    left: calc(50% - 164px + 0.5px);
    bottom: 50px;
    font-size: 16px;
    font-weight: 700;
    background-color: #00FB55;
    color: #000000;
  }
  #hawaii-event .back-button-red {
    position: absolute;
    width: 328px;
    height: 48px;
    bottom: 50px;
    left: calc(50% - 164px + 0.5px);
    font-size: 16px;
    font-weight: 700;
    background-color: #FF1D38;
    color: #FFFFFF;
  }
  #hawaii-event .back-button-black2 {
    position: absolute;
    width: 328px;
    height: 48px;
    bottom: 50px;
    left: calc(50% - 164px + 0.5px);
    font-size: 16px;
    font-weight: 700;
    background-color: #000000;
    color: #FFFFFF;
  }
}/*# sourceMappingURL=Event.css.map */