.loading-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #ffffffe0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .loading-text{
        font: 1rem 'Noto Sans KR';
        text-align: center;
    }
}