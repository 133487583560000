#auth-find-password .auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#auth-find-password .auth-options h1 {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  #auth-find-password .auth-options {
    margin-top: 100px;
    width: 452px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  #auth-find-password .auth-options {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
  }
}
#auth-find-password .auth-options .auth-aka-logo {
  cursor: pointer;
  display: block;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 479px) {
  #auth-find-password .auth-options .auth-aka-logo {
    margin-top: 50px;
  }
}
#auth-find-password .auth-options .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #auth-find-password .auth-options .auth-option-field {
    width: 328px;
  }
}
#auth-find-password .auth-button {
  margin-top: 40px;
  width: 452px;
  height: 64px;
  color: #FFFFFF;
  font-size: 24px;
  border-radius: 8px;
}
@media (max-width: 479px) {
  #auth-find-password .auth-button {
    width: 328px;
  }
}
#auth-find-password .auth-find {
  width: 100%;
}
#auth-find-password .auth-find-id {
  width: 100%;
  margin-top: 40px;
  color: #7E8288;
}
#auth-find-password .auth-find-id u {
  font-size: 16px;
  font-weight: 400;
}/*# sourceMappingURL=AuthFindPassword.css.map */