@import 'Global';
@import 'MyPage';


#update-info-screen {
  @include common-header;
  @include mobile {
    margin: auto;
    margin: 24px;
  }
  .user-info-grid {
    @include desktop {
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 24px;
    }
  
    span {
      margin-bottom: 8px;
      color: $cGray;
    }

    .user-info-namefield {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .username {
        display: flex;
        flex-direction: column;
        width:45%;
      }
    }

    .user-info-field {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .user-info-password {
        width: 100%;
        display: grid;
        grid-template-columns: 70% 20%;
        gap: 10%;
        .modify-password {
          border: 1px solid $cSecondary;
          border-radius: 4px;
        }
      } 
    }
  }


  .user-extra-field {
    display: flex;
    flex-direction: column;

    .modify-button {
      font-size: 24px;
      font-weight: 700;
      border-radius: 8px;
      width: 50%;
      height: 64px;
      margin-top: 80px;
      @include mobile {
        width: 328px;
      }
    }

    a {
      margin-top: 24px;
      color: $cBlack;
      cursor: pointer;
    }
    
  }  
}
