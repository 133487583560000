@import 'Global';

#root {
  width: 100%;
  padding: 0px;
  margin: 0px;
  .under-nav {
    padding-top: $nav-height;
    @include desktop {
      min-height: 700px;
    }
  }
  .dev-noti {
    position: fixed;
    top: 40px;
    left: 10px;
    color: red;
    z-index: 1000;
  }
  #kakao-talk-channel-chat-button {
    right: 30px;
    position: fixed;
    z-index: 1000;
    bottom: 100px;
    @include mobile {
      bottom: 100px
    }
  }

  .top-button {
    position: fixed;
    z-index: 1000;
    right: 30px;
    width : 65px;
    bottom: 160px;
    height: 65px;
    background: url('/public/images/mainPage/topButton.svg') no-repeat;
    cursor: pointer;
    @include mobile {
      bottom: 160px;
      right: 15px;
      width: 40px;
      height: 40px;
      background: url('/public/images/mainPageMobile/topButton_m.svg') no-repeat;
    }
  }
  .top-button:hover {
    background: url('/public/images/mainPage/topButtonHover.svg') no-repeat;
    @include mobile{
      background: url('/public/images/mainPageMobile/topButton_m.svg') no-repeat;
    }
  }
  font-family: 'Noto Sans KR';
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/NotoSansKR-Thin.otf") format('opentype'),
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/NotoSansKR-Light.otf") format('opentype'),
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/NotoSansKR-Regular.otf") format('opentype'),
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/NotoSansKR-Medium.otf") format('opentype'),
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/NotoSansKR-Bold.otf") format('opentype'),
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/NotoSansKR-Black.otf") format('opentype'),
}
