#mypage-main {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}
@media (min-width: 768px) {
  #mypage-main {
    max-width: 1080px;
  }
}
#mypage-main .menu-container {
  width: 250px;
}
#mypage-main .menu-container .greet-message {
  margin-top: 35px;
}
#mypage-main .menu-container .menu-pane {
  margin-top: 50px;
  padding-right: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  height: 30px;
}
#mypage-main .menu-container .menu-pane hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin: 0px;
}
#mypage-main .menu-container .menu-pane .menu-list {
  font-size: 17px;
}
#mypage-main .menu-container .menu-pane .menu-btn {
  font-size: 18px;
  color: #28292B;
  justify-content: flex-start;
  padding: 10px;
}
#mypage-main .menu-container .menu-pane .logout-btn {
  font-size: 18px;
  color: #f67f68;
  justify-content: flex-start;
}
#mypage-main .menu-container .menu-pane .selected {
  background-color: rgba(23, 88, 255, 0.05);
  color: #2388ff;
  font-weight: 900;
}
@media (min-width: 768px) {
  #mypage-main .content-container {
    flex: 1;
    margin: 20px;
    max-width: 1000px;
    min-height: 1600px;
  }
}

.drawer-container span {
  color: black;
  font-size: 15px;
}
.drawer-container .close-icon {
  padding: 30px;
  float: right;
}
.drawer-container .item-box {
  cursor: pointer;
  padding: 20px;
  padding-left: 30px;
}
.drawer-container .logout-btn {
  margin-top: 10px;
  font-size: 15px;
  color: #f67f68;
  justify-content: flex-start;
  padding-left: 30px;
}
.drawer-container img {
  margin-left: 30px;
}
.drawer-container .user-name {
  margin-left: 10px;
  position: absolute;
  margin-top: 10px;
  color: #7E8288;
}

#check-order-screen hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin-bottom: 24px;
}
#check-order-screen h3 {
  color: #28292B;
}
#check-order-screen .gray {
  color: #979797;
}
@media (max-width: 479px) {
  #check-order-screen h3 {
    margin: auto;
  }
}

.orderp-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.orderp-container span {
  color: #979797;
}
.orderp-container .detail-container {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.orderp-container .detail-container .detail-image {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}
.orderp-container .detail-container .detail-image .full {
  width: 100%;
  height: 100%;
}
.orderp-container .detail-container .detail-box {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  flex: 1;
}
.orderp-container .detail-container .detail-box span {
  color: #28292B;
}
.orderp-container .detail-container .price-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.orderp-container .detail-container .price-box span {
  font-size: 15px;
  font-weight: 900;
  color: #28292B;
}
.orderp-container .detail-container .price-box .blue {
  color: #2388ff;
}
.orderp-container .detail-container .price-box .price-detail {
  font-weight: 400;
}
.orderp-container .delivery-memo {
  margin-bottom: 20px;
}
@media (max-width: 479px) {
  .orderp-container .price-box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .orderp-container .price-box span {
    font-size: 15px;
    font-weight: 900;
    color: #28292B;
  }
  .orderp-container .price-box .blue {
    color: #2388ff;
  }
  .orderp-container .price-box .price-detail {
    font-weight: 400;
  }
}/*# sourceMappingURL=MyPageCheckOrder.css.map */