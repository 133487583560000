$nav-height: 112px;
$nav-height-mobile: 60px;

// reponsive settings
$br-sm: 480px;
$br-md: 768px;
$br-pad: 821px;
$br-lg: 1024px;
$br-xl: 1440px;

@mixin mobile {
  @media (max-width: #{$br-sm - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$br-md}) and (max-width: #{$br-pad - 1px}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$br-pad}) {
    @content;
  }
}

// image quality optimization



// global color

$cLeftGrad: #4dff94;
$cRightGrad: #47deff;
$cPrimary: #68d4d4;
$cSecondary: #2388ff;

$cLightGray: #F7F7F8;
$cGray: #979797;
$cDarkGray: #7E8288;
$cNavy: #39445F;
$cBlack: #28292B;
