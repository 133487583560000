@import 'Global';

#travel-screen {
  margin: auto;
  .section {
    max-width: 1920px;
    text-align: center;
  }

  img {
    max-width: 1080px;
    width: 100%;
  }

  .main1 {
    @include desktop {
      background: url('/public/images/mainPage/travelMain1.svg') no-repeat;
      background-position: center;
      height: 660px;
    }
    
    @include mobile {
      margin-top:-30px;
    }
  }

  .main2 {
    @include desktop {
      background-color: #A1C8FF;
      img {
        margin-top: 46px;
        margin-bottom: 120px;
      }
    }
    @include mobile {
      margin-top: 52px;
      margin-bottom: 16px;
      img {
        width: 80%;
      }
    }
  
  }

  .main3 {
    background-color: #DBB7FF;
    @include mobile {
      background: url('/public/images/mainPageMobile/travelMain3_m.svg') no-repeat;
      background-position: center;
      .travel-textbook {
        height: 505px;
        margin-bottom: 38px;
        img {
          margin-top: 50px;
          width: 321px;
          height: 341px;
        }
      }
    }
  }

  .main4 {
    margin: auto;
    height: 1126px;
    max-width: 1080px;
    background: url('/public/images/mainPage/travelMain4.svg') no-repeat;
    background-position: center;
    padding-top: 116px;

    .travel-textbook {
      max-width: 900px;
      padding-top: 250px;
      img {
        width: 714px;
        height: 665px;
      }

      .swiper-button-next {
        margin-top: 150px;
      }
      .swiper-button-prev {
        margin-top: 150px;
      }
    }
  }

  .main5 {
    background-color: #E4E4E4;
  }

  .main6 {
    background-color: #000000;
  }


  .swiper-button-next {
    width: 64px;
    height: 64px;
    background: url('/public/images/mainPage/swiperRight.svg') no-repeat;
  }
  .swiper-button-next:hover {
    background: url('/public/images/mainPage/swiperRightHover.svg') no-repeat;
  }
  .swiper-button-prev {
    width: 64px;
    height: 64px;
    background: url('/public/images/mainPage/swiperLeft.svg') no-repeat;
  }
  .swiper-button-prev:hover {
    background: url('/public/images/mainPage/swiperLeftHover.svg') no-repeat;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
}