@import 'Global';
@import 'Auth';

#auth-signup {
  @include auth-outline;
  .auth-signup-namefield {
    display: grid;
    gap: 16px;
    @include desktop {
      grid-template-columns: 218px 218px;
    }
    @include mobile {
      grid-template-columns: 156px 156px;
    }
  }

  .auth-signup-field {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 8px;
    .input-text {
      margin-top: 5px;
    }

    span{
      span {
        color:#F67F68;
      }
    }
  }

  .check-button {
    float: left;
    align-items: center;
    width: 100%;
    label {
      span {
        color: #FF7155;
        padding-left: 5px;
      }
    }
    .show-terms {
      float: right;
      cursor: pointer;
      margin-top: 3px;
    }

  }

  .terms {
    margin-top:16px;
    margin-bottom: 40px;
    width: 100%;
    color: $cDarkGray;
    @include desktop {
      padding-left: 60px;
    }
  }
  
  .submit {
    margin-bottom: 120px;
    width:100%;
 
    .auth-button {
      margin-top: 60px;
      height: 64px;
      width: 100%;
      font-size:24px;
      font-weight: 700;
      border-radius: 8px;
    }
  }
}