#intro-screen img {
  image-rendering: -webkit-optimize-contrast;
}
#intro-screen .section {
  background-color: white;
}
@media (min-width: 820px) {
  #intro-screen .section {
    position: relative;
    z-index: 1;
    padding: 0px;
    margin: 0px;
  }
}
#intro-screen .section .swiper-pagination-bullets {
  display: none;
}
#intro-screen .section .slide-img {
  display: flex;
  text-align: center;
}
@media (max-width: 479px) {
  #intro-screen .section {
    color: #28292B;
    text-align: center;
  }
  #intro-screen .section .header {
    font-size: 24px;
    font-weight: 700;
  }
  #intro-screen .section .mobile-section {
    width: 100%;
    align-items: center;
  }
}
#intro-screen .section.main1 {
  background-color: #F7F7F8;
  width: 100%;
}
#intro-screen .section.main1 .limit-img {
  margin: auto;
  max-width: 1440px;
}
@media (max-width: 479px) {
  #intro-screen .section.main1 {
    height: 1400px;
    margin: auto;
    background: #F7F7F8;
  }
  #intro-screen .section.main1 .normal {
    font-size: 15px;
  }
  #intro-screen .section.main1 span {
    color: #28292B;
    font-size: 24px;
    font-weight: 700;
  }
  #intro-screen .section.main1 span.normal {
    font-size: 15px;
    font-weight: 700;
  }
}
#intro-screen .section.blog-review {
  background-color: #FFFFFF;
  width: 100%;
}
#intro-screen .section.blog-review .limit-img {
  margin: auto;
  max-width: 1440px;
}
#intro-screen .section.blog-review .review-btn {
  width: 286px;
  height: 64px;
  font-size: 24px;
}
@media (max-width: 479px) {
  #intro-screen .section.blog-review .review-btn {
    width: 170px;
    height: 32px;
    font-size: 14px;
  }
}
@media (max-width: 479px) {
  #intro-screen .section.blog-review {
    margin: auto;
    background: #F7F7F8;
  }
  #intro-screen .section.blog-review span {
    color: #28292B;
    font-size: 24px;
    font-weight: 700;
  }
}
#intro-screen .section.main-add {
  background-color: #39445F;
  width: 100%;
}
#intro-screen .section.main-add .limit-section {
  position: relative;
  max-width: 1080px;
  padding-right: 40px;
}
#intro-screen .section.main-add .limit-section .main-add-text {
  position: absolute;
  left: 14%;
  margin-top: 160px;
  margin-bottom: 80px;
  width: 70%;
}
#intro-screen .section.main-add .limit-section .gift-img {
  padding-top: 290px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 160px;
}
#intro-screen .section.main-add .limit-section .gift-img .gift-img-specific {
  width: 30%;
}
@media (max-width: 479px) {
  #intro-screen .section.main-add {
    color: white;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (min-width: 820px) {
  #intro-screen .section.main2 {
    background: url("/public/images/mainPage/introMain2Background.png") no-repeat;
    background-size: cover;
    width: 100%;
    height: 1128px;
  }
  #intro-screen .section.main2 .limit-section {
    margin: auto;
    max-width: 1080px;
    padding-right: 40px;
  }
  #intro-screen .section.main2 .limit-section .ranking-img {
    display: flex;
    justify-content: space-between;
  }
  #intro-screen .section.main2 .limit-section .ranking-img .ranking-img-specific {
    width: 30%;
  }
  #intro-screen .section.main2 .limit-section .ranking-text {
    text-align: center;
  }
  #intro-screen .section.main2 .limit-section .ranking-text .purchase-btn {
    width: 424px;
    height: 80px;
    font-size: 24px;
  }
  #intro-screen .section.main2 .limit-section .ranking-text span {
    font-size: 18px;
  }
  #intro-screen .section.main2 .limit-section span {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  #intro-screen .section.main2 {
    background: url("/public/images/mainPageMobile/main2Background_m.svg") no-repeat;
    background-size: cover;
  }
  #intro-screen .section.main2 span {
    color: #28292B;
    font-weight: 700;
    font-size: 24px;
  }
  #intro-screen .section.main2 span span {
    color: #28292B;
    font-size: 14px;
    background: #FDFF88;
    padding: 6px 14px;
    font-weight: normal;
  }
}
@media (min-width: 820px) {
  #intro-screen .section.main3 {
    background-color: #F7F7F8;
  }
}
@media (max-width: 479px) {
  #intro-screen .section.main3 .title {
    font-size: 16px;
    font-weight: 700;
  }
  #intro-screen .section.main3 .text {
    font-size: 14px;
  }
}
#intro-screen .section.main4 {
  background-color: #28292B;
}
#intro-screen .section.main4 .limit-img {
  margin: auto;
  max-width: 1440px;
}
#intro-screen .section.main5 {
  background-color: white;
}
#intro-screen .section.main5 .limit-section {
  position: relative;
  z-index: 1;
  padding: 0px;
  margin: auto;
  max-width: 1440px;
}
#intro-screen .section.main6 {
  background-color: #F7F7F8;
  position: relative;
  z-index: 1;
  padding: 0px;
  margin: auto;
  max-width: 1440px;
}
@media (min-width: 820px) {
  #intro-screen .section.main7 {
    background-color: #39445F;
  }
}
@media (max-width: 479px) {
  #intro-screen .section.main7 {
    background-color: #F7F7F8;
  }
}
@media (max-width: 479px) {
  #intro-screen .section.main8 {
    background-color: #39445F;
  }
}
#intro-screen .section.main9 .limit-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#intro-screen .section.main9 .orgel-video {
  position: absolute;
  top: 25%;
}
@media (min-width: 820px) {
  #intro-screen .section.main10 {
    background-color: #28292B;
    margin: auto;
  }
  #intro-screen .section.main10 .img-bg {
    margin-bottom: -150px;
  }
  #intro-screen .section.main10 .orgel-video {
    position: absolute;
    width: 80%;
    top: 37%;
    left: 10%;
    z-index: 100;
    border-radius: 12px;
  }
  #intro-screen .section.main10 .orgel-text {
    position: absolute;
    display: block;
    margin: auto;
    top: 90%;
    justify-content: center;
    align-items: center;
    left: 42%;
  }
  #intro-screen .section.main10 .orgel-link {
    display: block;
    margin: auto;
    z-index: 100;
  }
  #intro-screen .section.main10 .figure-link {
    display: block;
    position: absolute;
    width: 27%;
    justify-content: center;
    align-items: center;
    left: 36.3%;
    top: 32%;
  }
}
@media (max-width: 479px) {
  #intro-screen .section.main10 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  #intro-screen .section.main10 .orgel-video {
    position: absolute;
    top: 17%;
    width: 90%;
    border-radius: 0px;
  }
}
#intro-screen .section.main11 {
  background-color: #28292B;
}
@media (min-width: 820px) {
  #intro-screen .section.main11 .purchase-btn {
    width: 380px;
    height: 80px;
    font-size: 24px;
  }
}
@media (max-width: 479px) {
  #intro-screen .section.main11 {
    position: relative;
  }
  #intro-screen .section.main11 .front-video {
    position: absolute;
    margin-top: 7%;
    height: 26%;
    width: auto;
    top: 40%;
    left: 9%;
    z-index: 100;
    border-radius: 12px;
  }
  #intro-screen .section.main11 .figure-link {
    display: block;
    position: absolute;
    width: 80%;
    justify-content: center;
    align-items: center;
    left: 10%;
    top: 38%;
  }
}
#intro-screen .section.main12 {
  background-color: #F7F7F8;
}
@media (max-width: 479px) {
  #intro-screen .section.main12 {
    position: relative;
    background-color: #28292B;
  }
  #intro-screen .section.main12 .pretty-btn {
    position: absolute;
    left: 16%;
    right: 16%;
    top: 600px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 18px;
    z-index: 10;
  }
}
@media (max-width: 479px) {
  #intro-screen .section.main13 .MuiCollapse-root {
    text-align: left;
  }
}
#intro-screen .limit-section {
  margin: auto;
  max-width: 1440px;
}
#intro-screen .faq-section {
  background: white;
}
#intro-screen .faq-section h1 {
  padding-top: 160px;
  padding-bottom: 160px;
  font-size: 64px;
  font-weight: 900;
  color: #28292B;
  text-align: center;
}
#intro-screen .faq-section .faq {
  margin: auto;
  max-width: 1080px;
  padding-bottom: 170px;
}
#intro-screen .intro-slider .carousel .control-dots .dot {
  border-radius: 0%;
  width: 30px;
  height: 5px;
  margin-bottom: 16px;
}
@media (max-width: 479px) {
  #intro-screen .intro-slider {
    background-color: #28292B;
  }
  #intro-screen .intro-slider .slick-slider {
    touch-action: auto;
    -ms-touch-action: auto;
  }
  #intro-screen .intro-slider .back-video {
    width: 100%;
  }
  #intro-screen .intro-slider .front-video {
    position: absolute;
    height: auto;
    width: 60%;
    top: 35%;
    right: 20%;
    border-radius: 12px;
    z-index: 100;
  }
  #intro-screen .intro-slider .orgel-video {
    position: absolute;
    width: 1%;
    height: 1%;
    left: calc(50% - 540px);
    top: 550px;
    z-index: 100;
    border-radius: 12px;
  }
  #intro-screen .intro-slider .title-container {
    width: 100%;
    height: 60px;
    margin-top: 24px;
  }
  #intro-screen .intro-slider .title-container .title {
    color: white;
    font-weight: 700;
    font-size: 26px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 2px 5px 5px rgba(0, 0, 0, 0.25), 2px -2px 2px rgba(0, 0, 0, 0.25), -2px -2px 2px rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 820px) {
  #intro-screen .intro-slider .back-video {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    z-index: -1;
  }
  #intro-screen .intro-slider .front-video {
    position: absolute;
    width: 40%;
    height: auto;
    top: 20%;
    right: 10%;
    z-index: 100;
    border-radius: 10px;
  }
  #intro-screen .intro-slider .intro-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0%;
    z-index: 100;
  }
  #intro-screen .intro-slider .intro-text {
    position: absolute;
    top: 30%;
    width: 20%;
    right: 10%;
  }
  #intro-screen .intro-slider .title-container {
    text-align: left;
    color: white;
    position: absolute;
    left: 10%;
    top: 30%;
  }
  #intro-screen .intro-slider .title-container .title {
    font-size: 3.1em;
  }
  #intro-screen .intro-slider .title-container .sub-title {
    font-size: 2.2em;
  }
}
#intro-screen .img-bg {
  width: 100%;
  background-color: white;
  display: block;
}
#intro-screen .popup-box {
  position: absolute;
  z-index: 3;
}
#intro-screen .popup-text {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  max-width: 1080px;
  z-index: 3;
}
#intro-screen .download-app {
  width: 100%;
}
@media (min-width: 820px) {
  #intro-screen .download-app {
    margin-top: -160px;
    margin-bottom: 120px;
  }
}
@media (max-width: 479px) {
  #intro-screen .download-app {
    margin-top: -200px;
    margin-bottom: 80px;
  }
}

#underline-box {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 104px;
  display: flex;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 479px) {
  #underline-box {
    flex-direction: column;
    height: 150px;
  }
}
#underline-box span {
  font-size: 22px;
  margin: 10px;
  background: linear-gradient(to right, #4DFF94, #47DFFD 15%, #FF9BE9 35%, #4DFF94 50%, #47DFFD 65%, #FF9BE9 85%, #4DFF94);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-animation: gradient 3s linear infinite;
          animation: gradient 3s linear infinite;
  background-size: 200% 100%;
}
@media (max-width: 479px) {
  #underline-box span {
    font-size: 18px;
  }
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 100%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 100%;
  }
}
#underline-box .close-btn {
  position: absolute;
  top: -30%;
  right: 20%;
}
@media (max-width: 479px) {
  #underline-box .close-btn {
    top: 0;
    right: 0;
  }
}
#underline-box .custom-btn {
  padding: 10px;
}
@media (max-width: 479px) {
  #underline-box .custom-btn {
    padding: 15px;
  }
}

.pretty-btn {
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 10px #eee;
  border-radius: 10px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #4dff94 0%, #47deff 50%, #4dff94 100%);
  cursor: pointer;
  font-size: 24;
  color: #222222;
  font-weight: 900;
}
.pretty-btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #28292B;
}/*# sourceMappingURL=MainPage.css.map */