#mypage-main {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}
@media (min-width: 768px) {
  #mypage-main {
    max-width: 1080px;
  }
}
#mypage-main .menu-container {
  width: 250px;
}
#mypage-main .menu-container .greet-message {
  margin-top: 35px;
}
#mypage-main .menu-container .menu-pane {
  margin-top: 50px;
  padding-right: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  height: 30px;
}
#mypage-main .menu-container .menu-pane hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin: 0px;
}
#mypage-main .menu-container .menu-pane .menu-list {
  font-size: 17px;
}
#mypage-main .menu-container .menu-pane .menu-btn {
  font-size: 18px;
  color: #28292B;
  justify-content: flex-start;
  padding: 10px;
}
#mypage-main .menu-container .menu-pane .logout-btn {
  font-size: 18px;
  color: #f67f68;
  justify-content: flex-start;
}
#mypage-main .menu-container .menu-pane .selected {
  background-color: rgba(23, 88, 255, 0.05);
  color: #2388ff;
  font-weight: 900;
}
@media (min-width: 768px) {
  #mypage-main .content-container {
    flex: 1;
    margin: 20px;
    max-width: 1000px;
    min-height: 1600px;
  }
}

.drawer-container span {
  color: black;
  font-size: 15px;
}
.drawer-container .close-icon {
  padding: 30px;
  float: right;
}
.drawer-container .item-box {
  cursor: pointer;
  padding: 20px;
  padding-left: 30px;
}
.drawer-container .logout-btn {
  margin-top: 10px;
  font-size: 15px;
  color: #f67f68;
  justify-content: flex-start;
  padding-left: 30px;
}
.drawer-container img {
  margin-left: 30px;
}
.drawer-container .user-name {
  margin-left: 10px;
  position: absolute;
  margin-top: 10px;
  color: #7E8288;
}

#modify-password .auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#modify-password .auth-options h1 {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  #modify-password .auth-options {
    margin-top: 100px;
    width: 452px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  #modify-password .auth-options {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
  }
}
#modify-password .auth-options .auth-aka-logo {
  cursor: pointer;
  display: block;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 479px) {
  #modify-password .auth-options .auth-aka-logo {
    margin-top: 50px;
  }
}
#modify-password .auth-options .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #modify-password .auth-options .auth-option-field {
    width: 328px;
  }
}
#modify-password .modify-header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}
#modify-password .modify-content {
  text-align: center;
}
#modify-password .modify-field {
  margin-top: 24px;
  margin-bottom: 8px;
  width: 100%;
}
#modify-password .modify-button {
  flex-direction: row;
  margin-top: 40px;
  width: 350px;
  height: 64px;
  font-weight: 700;
  font-size: 22px;
  border-radius: 8px;
}/*# sourceMappingURL=MyPageModifyPassword.css.map */