#admin-main {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
#admin-main .menu-container {
  display: flex;
  width: 250px;
  margin-top: 100px;
}
#admin-main .menu-container .greet-message {
  margin-top: 35px;
}
#admin-main .menu-container .menu-pane {
  margin-top: 50px;
  padding-right: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  height: 30px;
}
#admin-main .menu-container .menu-pane hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin: 0px;
}
#admin-main .menu-container .menu-pane .menu-list {
  font-size: 17px;
}
#admin-main .menu-container .menu-pane .menu-btn {
  font-size: 18px;
  color: #28292B;
  justify-content: flex-start;
  padding: 10px;
}
#admin-main .menu-container .menu-pane .logout-btn {
  font-size: 18px;
  color: #f67f68;
  justify-content: flex-start;
}
#admin-main .menu-container .menu-pane .selected {
  background-color: rgba(23, 88, 255, 0.05);
  color: #2388ff;
  font-weight: 900;
}
#admin-main .menu-container .content-container {
  position: absolute;
  right: 0px;
  margin: 20px;
}

#mypage-main {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}
@media (min-width: 821px) {
  #mypage-main {
    max-width: 1080px;
  }
}
#mypage-main .menu-container {
  width: 250px;
}
#mypage-main .menu-container .greet-message {
  margin-top: 35px;
}
#mypage-main .menu-container .menu-pane {
  margin-top: 50px;
  padding-right: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  height: 30px;
}
#mypage-main .menu-container .menu-pane hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin: 0px;
}
#mypage-main .menu-container .menu-pane .menu-list {
  font-size: 17px;
}
#mypage-main .menu-container .menu-pane .menu-btn {
  font-size: 18px;
  color: #28292B;
  justify-content: flex-start;
  padding: 10px;
}
#mypage-main .menu-container .menu-pane .logout-btn {
  font-size: 18px;
  color: #f67f68;
  justify-content: flex-start;
}
#mypage-main .menu-container .menu-pane .selected {
  background-color: rgba(23, 88, 255, 0.05);
  color: #2388ff;
  font-weight: 900;
}
@media (min-width: 821px) {
  #mypage-main .content-container {
    flex: 1;
    margin: 20px;
    max-width: 1000px;
    min-height: 1600px;
  }
}

.drawer-container span {
  color: black;
  font-size: 15px;
}
.drawer-container .close-icon {
  padding: 30px;
  float: right;
}
.drawer-container .item-box {
  cursor: pointer;
  padding: 20px;
  padding-left: 30px;
}
.drawer-container .logout-btn {
  margin-top: 10px;
  font-size: 15px;
  color: #f67f68;
  justify-content: flex-start;
  padding-left: 30px;
}
.drawer-container img {
  margin-left: 30px;
}
.drawer-container .user-name {
  margin-left: 10px;
  position: absolute;
  margin-top: 10px;
  color: #7E8288;
}

#study-main hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin-bottom: 24px;
}
#study-main h3 {
  color: #28292B;
}
#study-main .gray {
  color: #979797;
}
@media (max-width: 479px) {
  #study-main h3 {
    text-align: center;
  }
}
#study-main hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin: 0px;
}
#study-main .tab {
  font-size: 20px;
  font-weight: 700;
}
#study-main .tab.Mui-selected {
  background-color: #F3F9FF;
}
#study-main .item-row {
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 7%;
}
#study-main .item-row .section {
  width: 100px;
}
#study-main .item-row .title {
  flex: 1;
}
#study-main .item-row .btn-area {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 18px 20px 12px;
  gap: 10px;
  position: absolute;
  width: 40px;
  height: 50px;
  background-color: #FFFFFF;
  border: 0.25px solid #D2D2D2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
#study-main .item-row .btn-area .btn-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 40px;
  height: 46px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
}
#study-main .item-row .btn-area .btn-box li {
  display: flex;
  align-items: center;
  width: 23px;
  height: 13px;
}
#study-main .item-row .btn-area .btn-box li:hover {
  color: #2388ff;
  cursor: pointer;
}
#study-main .item-row .btn-area-s {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px 18px 8px 12px;
  gap: 10px;
  position: absolute;
  width: 40px;
  height: 25px;
  background-color: #FFFFFF;
  border: 0.25px solid #D2D2D2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
#study-main .item-row .btn-area-s .btn-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 40px;
  height: 46px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
}
#study-main .item-row .btn-area-s .btn-box li {
  display: flex;
  align-items: center;
  width: 23px;
  height: 13px;
}
#study-main .item-row .btn-area-s .btn-box li:hover {
  color: #2388ff;
  cursor: pointer;
}
#study-main .item-row .play-icon {
  width: 60px;
}
#study-main .item-row .play-icon .icon {
  stroke: grey;
}
#study-main .item-row .play-icon .icon:hover {
  cursor: pointer;
  stroke: #2388FF;
}
#study-main .item-row .file-icon {
  width: 60px;
}
#study-main .item-row .file-icon .icon {
  fill: grey;
}
#study-main .item-row .file-icon .icon:hover {
  cursor: pointer;
  fill: #2388FF;
}/*# sourceMappingURL=MyPageStudyRoom.css.map */