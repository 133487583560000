#business-screen {
  background-color: #000000;
  margin: auto;
}
#business-screen .section {
  max-width: 1920px;
  text-align: center;
}
#business-screen img {
  max-width: 1080px;
  width: 100%;
}
#business-screen .main1 {
  background: url("/public/images/mainPage/businessMain1.svg") no-repeat;
  background-position: center;
  height: 660px;
}
@media (max-width: 479px) {
  #business-screen .main1 {
    margin-top: -30px;
  }
}
@media (min-width: 768px) {
  #business-screen .main2 {
    background: url("/public/images/mainPage/businessMain2.svg") no-repeat;
    background-position: center;
    height: 714px;
  }
}
@media (max-width: 479px) {
  #business-screen .main2 {
    background-color: #FFFFFF;
  }
  #business-screen .main2 .slider-header {
    font-size: 18px;
    font-weight: 500;
  }
  #business-screen .main2 .slider-header span {
    font-size: 20px;
  }
  #business-screen .main2 .business-slide {
    margin-top: 39px;
    height: 436px;
  }
  #business-screen .main2 .business-slide img {
    width: 216px;
    height: 384px;
  }
  #business-screen .main2 .business-slide .swiper-slide:nth-child(2n) {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  #business-screen .main3 {
    background-color: #EAEAEA;
  }
  #business-screen .main3 img {
    max-width: 1080px;
    width: 100%;
  }
}
@media (max-width: 479px) {
  #business-screen .main3 {
    background: url("/public/images/mainPageMobile/businessMain4_m.svg");
    background-position: center;
    height: 650px;
  }
  #business-screen .main3 .business-textbook img {
    width: 312px;
    height: 288px;
    margin-top: 180px;
  }
  #business-screen .main3 .business-textbook .swiper-button-next {
    margin-top: 100px;
  }
  #business-screen .main3 .business-textbook .swiper-button-prev {
    margin-top: 100px;
  }
}
#business-screen .main4 {
  background-color: #FFFFFF;
  padding-top: 160px;
  padding-bottom: 220px;
}
#business-screen .main4 span {
  font-size: 40px;
  font-weight: 500;
}
#business-screen .main4 span span {
  font-size: 44px;
}
#business-screen .main4 .business-slide {
  margin-top: 80px;
  max-width: 1080px;
  height: 700px;
}
#business-screen .main4 .business-slide .swiper-slide:nth-child(2n) {
  margin-top: 100px;
}
@media (max-width: 479px) {
  #business-screen .main4 img {
    width: 90%;
    margin: auto;
  }
}
#business-screen .main6 {
  margin: auto;
  height: 1126px;
  max-width: 1080px;
  background: url("/public/images/mainPage/businessMain6.svg") no-repeat;
  background-position: center;
}
@media (min-width: 768px) {
  #business-screen .main6 .business-textbook {
    max-width: 900px;
    padding-top: 250px;
  }
  #business-screen .main6 .business-textbook img {
    width: 714px;
    height: 600px;
  }
  #business-screen .main6 .business-textbook .swiper-button-next {
    margin-top: 100px;
  }
  #business-screen .main6 .business-textbook .swiper-button-prev {
    margin-top: 100px;
  }
}
#business-screen .main7 {
  background-color: #FFFFFF;
  padding-bottom: 202px;
}
#business-screen .main8 {
  background-color: #BBDAFF;
}
#business-screen .main9 {
  background-color: #D5ABFF;
}
#business-screen .swiper-button-next {
  width: 64px;
  height: 64px;
  background: url("/public/images/mainPage/swiperRight.svg") no-repeat;
}
@media (max-width: 479px) {
  #business-screen .swiper-button-next {
    background-size: cover;
    width: 40px;
    height: 40px;
  }
}
#business-screen .swiper-button-next:hover {
  background: url("/public/images/mainPage/swiperRightHover.svg") no-repeat;
}
@media (max-width: 479px) {
  #business-screen .swiper-button-next:hover {
    background-size: cover;
    width: 40px;
    height: 40px;
  }
}
#business-screen .swiper-button-prev {
  width: 64px;
  height: 64px;
  background: url("/public/images/mainPage/swiperLeft.svg") no-repeat;
}
@media (max-width: 479px) {
  #business-screen .swiper-button-prev {
    background-size: cover;
    width: 40px;
    height: 40px;
  }
}
#business-screen .swiper-button-prev:hover {
  background: url("/public/images/mainPage/swiperLeftHover.svg") no-repeat;
}
@media (max-width: 479px) {
  #business-screen .swiper-button-prev:hover {
    background-size: cover;
    width: 40px;
    height: 40px;
  }
}
#business-screen .swiper-button-next::after,
#business-screen .swiper-button-prev::after {
  display: none;
}/*# sourceMappingURL=Business.css.map */