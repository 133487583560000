@import 'Global';
@import 'MyPage';
@import 'Auth';

#modify-password {
  @include auth-outline;

  .modify-header {
    font-size: 24px;
    font-weight:700;
    margin-bottom: 24px;
  }
  .modify-content {
    text-align: center;
    
  }
  .modify-field {
    margin-top: 24px;
    margin-bottom: 8px;
    width:100%;
  }

  .modify-button {
    flex-direction: row;
    margin-top: 40px;
    width: 350px;
    height: 64px;
    font-weight: 700;
    font-size: 22px;
    border-radius: 8px;
  }
}

