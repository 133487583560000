hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin-bottom: 24px;
}

.faq-subquestion {
  width: 95%;
  margin: auto;
}
.faq-subquestion hr {
  margin: 0;
  padding: 0;
}
.faq-subquestion span {
  white-space: pre-line;
}

#mypage-faq {
  background: white;
}
@media (min-width: 768px) {
  #mypage-faq {
    max-width: 760px;
  }
}
@media (max-width: 479px) {
  #mypage-faq {
    margin: 24px;
  }
  #mypage-faq h3 {
    text-align: center;
  }
}/*# sourceMappingURL=Faq.css.map */