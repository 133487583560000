@import 'Global';
@import 'Auth';

#auth-verify-email {
  @include auth-outline;

  .auth-user-email {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 8px;
  }

  .verify-button {
    display: flex;
    justify-content: space-between;
    width:100%;
    .verify-button-divide {
      flex-direction: row;
      margin-top: 40px;
      width: 210px;
      height: 64px;
      font-weight: 700;
      font-size: 24px;
      border-radius: 8px;
    }
    .logout-button {
      background-color: #FFFFFF;
      border: 1px solid #2388FF;
      color: #2388FF;
    }
  }

  .resent-verify-email {
      width: 100%;
      margin-top:40px;
      color: $cDarkGray;
      text-align: center;
      font-size: 16px;
    
  }
}