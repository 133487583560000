.mobile-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-slide h3 {
  color: #2388FF;
  font-size: 16px;
}
.mobile-slide .contents {
  white-space: pre;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.mobile-slide .footer {
  font-size: 16px;
  font-weight: 700;
}/*# sourceMappingURL=SlideImage.css.map */