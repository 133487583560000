@import 'Auth';

#auth-find-email {
  @include auth-outline;

  .auth-find {
    width: 100%;
  }

  .auth-option-namefield {
    display: grid;
    gap: 16px;
    @include desktop {
      grid-template-columns: 218px 218px;
    }
    @include mobile {
      grid-template-columns: 156px 156px;
      gap: 16px;
    }
    .auth-option-field {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      .input-text {
        margin-top: 5px;
      }
    }
  }

  .auth-option-field {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 8px;
  }

  .auth-button {
    margin-top:40px;
    width: 452px;
    height: 64px;
    color: #FFFFFF;
    font-size: 24px;
    border-radius: 8px;
    @include mobile {
      width: 328px;
    }
  }
}