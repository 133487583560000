@import 'Auth';
@import 'Global';

#auth-find-password {
  @include auth-outline;
  
  .auth-button {
    margin-top:40px;
    width: 452px;
    height: 64px;
    color: #FFFFFF;
    font-size: 24px;
    border-radius: 8px;
    @include mobile {
      width: 328px;
    }
  }

  .auth-find {
    width: 100%;
  }

  .auth-find-id {
    width: 100%;
    margin-top:40px;
    color: $cDarkGray;
    u {
      font-size:16px;
      font-weight: 400;
    }
  }
}