@import 'Global';

@mixin auth-outline {
  .auth-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1 {
      width:100%;
      text-align: left;
    }

    @include desktop {
      margin-top: 100px;
      width: 452px;
      margin-right: auto;
      margin-left: auto;
    }

    @include mobile {
      width: 328px;
      margin-right: auto;
      margin-left: auto;
    }

    .auth-aka-logo {
      cursor: pointer;
      display : block;
      margin:auto;
      margin-bottom: 40px;
      text-align: center;
      @include mobile {
        margin-top: 50px;
      }
    }

    .auth-option-field {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 8px;
      font-size: 18px;
      @include mobile {
        width: 328px;
      }
    }
  }
}