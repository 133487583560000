@import 'Global';

#event-main {
  @include desktop {
    max-width: 1080px;
    margin: auto;
    .title{
      display: flex;
      justify-content: space-between;
    }
    .event-list {
      height: 358px;
      margin-bottom: 200px;
      cursor: pointer;
    }
  }

  @include mobile {
    h2 {
      margin-top: -120px;
      text-align: center;
    }
    max-width: 327px;
    margin: auto;
   
  }
}

#hawaii-event {
  margin: auto;
  max-width: 100%;
  text-align: center;
  
  .hawaii-header {
    max-width: 1080px;
    margin: auto;
    text-align: center;

    .sns-row {
      display: flex;
      height: 24px;
      margin-top:6px;
      @include desktop {
        float: right;
        
      }
      @include mobile {
        justify-content: center;
      }
      img {
        justify-content: space-between;
      }
      .facebook-icon{
        margin-left: 18px;
        margin-right: 25px;
      }
      .facebook-icon:hover {
        content: url('/public/images/misc/facebookIconHover.png');
      }
      .twitter-icon {
        margin-left: 18px;
        margin-right: 25px;
      }
      .twitter-icon:hover {
        content: url('/public/images/misc/twitterIconHover.png');
      }
      .link-icon:hover {
        content: url('/public/images/misc/linkIconHover.png');
      }
    }
    span {
      span {
        color: $cSecondary;
      }
    }

    @include mobile {
      .title {
        margin-top: 60px;
        font-size: 18px;
        font-weight: 700;
      }
      span {
        span {
          color: $cSecondary;
        }
      }
    }

    @include desktop {
      .title {
        margin-top: 100px;
        margin-left: 148px;
        font-weight: 700;
        font-size: 24px;
      }
    }
    
  }
  .event-bar-fixed {
    display: flex;
    position: fixed;
    background-color: white;
    justify-content: space-around;
    top: 0px;
    height: 113px;
    width: 100%;
    z-index: 501;
    @include mobile {
      width: 100%;
      justify-content: space-between;
      margin-left: 0px;
      margin-top: 80px;
      height: 64px;
    }
    .item-holder {
      @include mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 0px;
        margin-bottom: 0px;
        margin-top: 6px;
      }
      margin-left: 0%;
      margin-bottom: 5px;
      .item {
        display: inline-block;
        width: 360px;
        height: 100px;
        cursor: pointer;
        margin-top: 12px;
        @include mobile {
          margin-top: 0px;
        }
        .item-text {
          font-size: 20px;
          color: #28292B;
          @include mobile{
            font-size: 14px;
            font-weight: 500;
            height: 64px;
          }
        }
        .item-text-fixed {
          display: block;
          content: '';
          font-size: 20px;
          color: #28292B;
          padding-bottom: 22px;
          border-bottom: solid 5px #28292B;
          transition: transform 250ms ease-in-out;
          @include mobile {
            font-size: 14px;
            font-weight: 500;
            height: auto;
            padding-bottom: 13px;
            color: #28292B;
            border-bottom: solid 5px #28292B;
            transition: transform 250ms ease-in-out;
          }
        }
        .item-text::after {
          display: block;
          content: '';
          padding-bottom: 22px;
          border-bottom: solid 5px #28292B;
          transform: scaleX(0);  
          transition: transform 250ms ease-in-out;
          @include mobile {
            padding-bottom: 5px;
            
          }
        }

        .item-text:hover::after {
          transform: scaleX(1);
        }
      }
    }
  }
  .event-bar {
    display: flex;
    justify-content: space-around;
    background-color: white;
    height: 100px;
    position: relative;
    @include mobile {
      width: 100%;
      height: 64px;
    }
    .item-holder {
      @include mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 0px;
        margin-bottom: 0px;
        margin-top: 6px;
      }
      margin-left: 0%;
      margin-bottom: 5px;
      .item {
        display: inline-block;
        width: 360px;
        cursor: pointer;
        .item-text {
          font-size: 20px;
          color: #28292B;
          @include mobile {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .item-text::after {
          display: block;
          content: '';
          padding-bottom: 22px;
          border-bottom: solid 5px #28292B;
          transform: scaleX(0);  
          transition: transform 250ms ease-in-out;
          @include mobile {
            padding-bottom: 5px;
          }
        }
        .item-text:hover::after {
          transform: scaleX(1);
        }
      }
    }
  }
  .hawaii-main {
    // max-width: 100%;
    @include mobile {
      width: 100%;
      &.orgel-download {
        margin-top: 80px;
        width: 75%;
      }
      &.orgel-download-ios {
        width: 35%;
        margin-top: 19px;
        margin-bottom: 50px;
      }
    }
    height: auto;
    margin-bottom:-10px;
    &.orgel-download {
      position: relative;

      .orgel-download-step {
        max-width: 100%;
      }
      .orgel-ios-download {
        position: absolute;
        right: 45%;
        top: 43%;
        width: 10%;
        z-index: 1;
      }
    }
  }
  
  .hawaii-apply {
    color: #FFFFFF;
    text-align: center;
    height: 608px;
    
    @include desktop {
      background: url('/public/images/mainPage/eventMain5.png');
      h1 {
        padding-top: 160px;
        font-weight: 900;
        font-size: 3em;
      }
      span {
        font-size: 1.5em;
      }
      .apply-button {
        font-size: 24px;
        font-weight: 700;
        border-radius: 8px;
        width: 424px;
        height: 64px;
        margin-top: 27px;
      }
    }
    @include mobile {
      background: url('/public/images/mainPageMobile/eventMain6_m.png');
      background-size: cover;
      h1 {
        font-size: 2.25em;
        font-weight: 900;
        padding-top: 80px;
      }
      span {
        font-size: 1.125em;
      }
      .apply-button {
        font-size: 16px;
        font-weight: 700;
        border-radius: 8px;
        width: 328px;
        height: 48px;
        margin-top: 27px;
      }

    }


    .form-button-text {
      font-size: 1.125em;
      color: #000000;
      margin-top: 94px;
    }
  }
  .hawaii-qr {
    margin-top: -25px;
    background: #434D6B;
    .plate-button {
      @include desktop {
        font-size: 24px;
        font-weight: 700;
        border-radius: 8px;
        width: 424px;
        height: 64px;
        margin-top: 27px;
      }
      @include mobile {
        font-size: 16px;
        font-weight: 700;
        border-radius: 8px;
        width: 328px;
        height: 48px;
        margin-top: 27px;
        margin-bottom: 60px;
      }
    }
    a {
      text-decoration-line: none;
    }
    
    .musio-qr {
      position: relative;
      .is-not-login {
        position: absolute;
        text-decoration: underline;
        color: #7F8184;
        @include desktop {
          transform : translate(-365px);
          top:45%;
        }
        @include mobile {
          transform: translate(-180px);
          top: 35%;
        }
      }
      .is-login {
        position: absolute;
        text-decoration: underline;
        color: #7F8184;
        @include desktop {
          width: 180px;
          height: 180px;
          transform : translate(-400px);
          top:28%;
        }
        @include mobile {
          width: 124px;
          height: 124px;
          transform: translate(-218px);
          top: 26%;
        }
      }
    }
  }

  .hawaii-notice {
    margin: auto;
    @include desktop {
      width: 1000px;
    }
  }
  .hawaii-main1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: auto;
  }
  .limit-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  .event-ref {
    position: relative;
    top: -100px;
    @include mobile {
      position: relative;
      top: -104px;
    }
  }
  
  .back-button {
    width: 452px;
    height: 64px;
    font-size: 24px;
    font-weight:700;
    background-color: #FFFFFF;
    border: 1px solid $cSecondary;
    color: $cSecondary;
  }
  .back-button-green1 {
    position: relative;
    bottom: 101px;
    left: 0%;
    width: 424px;
    height: 80px;
    font-size: 24px;
    font-weight:700;
    background-color: #00FB55;
    // border: 1px solid $cSecondary;
    color: $cBlack;
  }
  .back-button-red1 {
    position: relative;
    bottom: 101px;
    left: 0%;
    width: 424px;
    height: 80px;
    font-size: 24px;
    font-weight:700;
    background-color: #FF1D38;
    // border: 1px solid $cSecondary;
    color: #FFFFFF;
  }
  .back-button-black2 {
    position: relative;
    bottom: 101px;
    left: 0%;
    width: 424px;
    height: 80px;
    font-size: 24px;
    font-weight:700;
    background-color: #000000;
    // border: 1px solid $cSecondary;
    color: #FFFFFF;
  }
  @include mobile {
    .back-button {
      width: 156px;
      height: 48px;
      font-size: 16px;
      font-weight:700;
      background-color: #FFFFFF;
      border: 1px solid $cSecondary;
      color: $cSecondary;
    }
    // .back-button-black1 {
    //   position: absolute;
    //   margin-top: 95%;
    //   left: 10%;
    //   width: 80%;
    //   height: 6%;
    //   font-size: 16px;
    //   font-weight:700;
    //   background-color: #000000;
    //   // border: 1px solid $cSecondary;
    //   color: #FFFFFF;
    // }
    .back-button-green1 {
      position: absolute;
      width: 328px;
      height: 48px;
      left: calc(50% - 328px/2 + 0.5px);
      bottom: 50px;
      font-size: 16px;
      font-weight:700;
      background-color: #00FB55;
      // border: 1px solid $cSecondary;
      color: #000000;
    }
    .back-button-red {
      position: absolute;
      width: 328px;
      height: 48px;
      bottom: 50px;
      left: calc(50% - 328px/2 + 0.5px);
      font-size: 16px;
      font-weight:700;
      background-color: #FF1D38;
      // border: 1px solid $cSecondary;
      color: #FFFFFF;
    }
    .back-button-black2 {
      position: absolute;
      width: 328px;
      height: 48px;
      bottom: 50px;
      left: calc(50% - 328px/2 + 0.5px);
      font-size: 16px;
      font-weight:700;
      background-color: #000000;
      // border: 1px solid $cSecondary;
      color: #FFFFFF;
    }
  }
}