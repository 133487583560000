#auth-resign .auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#auth-resign .auth-options h1 {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  #auth-resign .auth-options {
    margin-top: 100px;
    width: 452px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  #auth-resign .auth-options {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
  }
}
#auth-resign .auth-options .auth-aka-logo {
  cursor: pointer;
  display: block;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 479px) {
  #auth-resign .auth-options .auth-aka-logo {
    margin-top: 50px;
  }
}
#auth-resign .auth-options .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #auth-resign .auth-options .auth-option-field {
    width: 328px;
  }
}
@media (max-width: 479px) {
  #auth-resign {
    margin: auto;
    margin: 24px;
  }
}
#auth-resign .resign-header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}
#auth-resign .resign-content {
  text-align: center;
}
#auth-resign .resign-field {
  margin-top: 24px;
  margin-bottom: 8px;
  width: 100%;
}
#auth-resign .resign-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 479px) {
  #auth-resign .resign-button {
    gap: 24px;
  }
}
#auth-resign .resign-button .resign-button-divide {
  flex-direction: row;
  margin-top: 40px;
  width: 210px;
  height: 64px;
  font-weight: 700;
  font-size: 24px;
  border-radius: 8px;
}
#auth-resign .resign-button .cancel-resign {
  background-color: #FFFFFF;
  border: 1px solid #2388FF;
  color: #2388FF;
}/*# sourceMappingURL=AuthResign.css.map */