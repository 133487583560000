#mypage-main {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}
@media (min-width: 768px) {
  #mypage-main {
    max-width: 1080px;
  }
}
#mypage-main .menu-container {
  width: 250px;
}
#mypage-main .menu-container .greet-message {
  margin-top: 35px;
}
#mypage-main .menu-container .menu-pane {
  margin-top: 50px;
  padding-right: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  height: 30px;
}
#mypage-main .menu-container .menu-pane hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin: 0px;
}
#mypage-main .menu-container .menu-pane .menu-list {
  font-size: 17px;
}
#mypage-main .menu-container .menu-pane .menu-btn {
  font-size: 18px;
  color: #28292B;
  justify-content: flex-start;
  padding: 10px;
}
#mypage-main .menu-container .menu-pane .logout-btn {
  font-size: 18px;
  color: #f67f68;
  justify-content: flex-start;
}
#mypage-main .menu-container .menu-pane .selected {
  background-color: rgba(23, 88, 255, 0.05);
  color: #2388ff;
  font-weight: 900;
}
@media (min-width: 768px) {
  #mypage-main .content-container {
    flex: 1;
    margin: 20px;
    max-width: 1000px;
    min-height: 1600px;
  }
}

.drawer-container span {
  color: black;
  font-size: 15px;
}
.drawer-container .close-icon {
  padding: 30px;
  float: right;
}
.drawer-container .item-box {
  cursor: pointer;
  padding: 20px;
  padding-left: 30px;
}
.drawer-container .logout-btn {
  margin-top: 10px;
  font-size: 15px;
  color: #f67f68;
  justify-content: flex-start;
  padding-left: 30px;
}
.drawer-container img {
  margin-left: 30px;
}
.drawer-container .user-name {
  margin-left: 10px;
  position: absolute;
  margin-top: 10px;
  color: #7E8288;
}

#update-info-screen hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin-bottom: 24px;
}
#update-info-screen h3 {
  color: #28292B;
}
#update-info-screen .gray {
  color: #979797;
}
@media (max-width: 479px) {
  #update-info-screen {
    margin: auto;
    margin: 24px;
  }
}
@media (min-width: 768px) {
  #update-info-screen .user-info-grid {
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 24px;
  }
}
#update-info-screen .user-info-grid span {
  margin-bottom: 8px;
  color: #979797;
}
#update-info-screen .user-info-grid .user-info-namefield {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#update-info-screen .user-info-grid .user-info-namefield .username {
  display: flex;
  flex-direction: column;
  width: 45%;
}
#update-info-screen .user-info-grid .user-info-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#update-info-screen .user-info-grid .user-info-field .user-info-password {
  width: 100%;
  display: grid;
  grid-template-columns: 70% 20%;
  gap: 10%;
}
#update-info-screen .user-info-grid .user-info-field .user-info-password .modify-password {
  border: 1px solid #2388ff;
  border-radius: 4px;
}
#update-info-screen .user-extra-field {
  display: flex;
  flex-direction: column;
}
#update-info-screen .user-extra-field .modify-button {
  font-size: 24px;
  font-weight: 700;
  border-radius: 8px;
  width: 50%;
  height: 64px;
  margin-top: 80px;
}
@media (max-width: 479px) {
  #update-info-screen .user-extra-field .modify-button {
    width: 328px;
  }
}
#update-info-screen .user-extra-field a {
  margin-top: 24px;
  color: #28292B;
  cursor: pointer;
}/*# sourceMappingURL=MyPageUserInfo.css.map */