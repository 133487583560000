
@import 'MyPage';
@import 'Global';

#check-order-screen {
  @include common-header;
  @include mobile {
    h3 {
      margin: auto;
    }
  }
}

.orderp-container {
  margin-top: 10px;
  margin-bottom:10px;
  display: flex;
  flex-direction: column;
  span {
    color: $cGray;
  }
  .detail-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    .detail-image {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      .full {
        width: 100%;
        height: 100%;
      }
    }

    .detail-box {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      flex: 1;
      span {
        color: $cBlack;
      }
    }
    .price-box {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      span {
        font-size: 15px;
        font-weight: 900;
        color: $cBlack;
      }
      .blue {
        color: $cSecondary;
      }
      .price-detail {
        font-weight: 400;
      }
    }
  }
  .delivery-memo {
    margin-bottom: 20px;
  }
  @include mobile {
    .price-box {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      span {
        font-size: 15px;
        font-weight: 900;
        color: $cBlack;
      }
      .blue {
        color: $cSecondary;
      }
      .price-detail {
        font-weight: 400;
      }
    }
  }
}