.loading-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8784313725);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-background .loading-text {
  font: 1rem "Noto Sans KR";
  text-align: center;
}/*# sourceMappingURL=Loading.css.map */