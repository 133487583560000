#navbar {
  border-bottom: solid 1px #E4E8F4;
  background-color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: top 0.3s;
  left: 0;
  right: 0;
  top: 0;
  height: 112px;
  z-index: 500;
  height: 112px;
}
@media (max-width: 479px) {
  #navbar {
    height: 60px;
    flex-direction: row;
    height: 80px;
  }
  #navbar .logo-box {
    padding: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 12px;
    width: 100%;
  }
  #navbar .logo-box .logo {
    -o-object-fit: contain;
       object-fit: contain;
    cursor: pointer;
    width: 154px;
  }
  #navbar .logo-box .item-holder {
    flex: 1;
  }
  #navbar .logo-box .item-holder .item {
    padding: 26px;
    cursor: pointer;
    font-size: 18px;
  }
  #navbar .logo-box .item-holder .item:hover {
    font-weight: 900;
  }
}
@media (min-width: 821px) {
  #navbar .top-section {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #686868;
  }
  #navbar .top-section .open-menu {
    font-size: 13px;
    color: #686868;
  }
  #navbar .top-section .open-menu span {
    position: absolute;
    top: 10px;
    width: 100%;
    left: -20%;
  }
  #navbar .top-section .open-menu img {
    position: absolute;
  }
  #navbar .top-section .open-menu .family-hover {
    margin-left: 83px;
    margin-top: 17px;
  }
  #navbar .top-section .open-menu .family-margin {
    left: 0;
  }
  #navbar .top-section .family-site {
    z-index: 100;
    float: left;
    position: absolute;
    margin-top: 35px;
    width: 78px;
    height: 86px;
    list-style: none;
    transition: transform 0.3s;
    transition-delay: 0.2s;
    background: #FFFFFF;
    border: 0.25px solid #D2D2D2;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  #navbar .top-section .family-site li {
    margin-top: 20px;
    margin-left: -20px;
  }
  #navbar .top-section .family-site li a {
    text-decoration: none;
    color: inherit;
  }
  #navbar .top-section .family-site li:hover {
    color: #2388ff;
    cursor: pointer;
  }
  #navbar .top-section .family-site:not(:hover) {
    transform: scaleY(0);
  }
  #navbar .top-section .open-menu:hover ~ .family-site {
    transform: scaleY(1);
  }
  #navbar .top-section .open-menu:hover .after-hover {
    display: block;
  }
  #navbar .top-section .open-menu:hover .before-hover {
    display: none;
  }
  #navbar .top-section .after-hover {
    display: none;
  }
  #navbar .top-section .sign-box {
    width: 108px;
    text-align: right;
    position: relative;
  }
  #navbar .top-section .sign-box .user-dropdown {
    margin-top: -11px;
    margin-right: 50px;
  }
  #navbar .top-section .sign-box .user-dropdown .dropdown-section {
    width: 108px;
    margin-left: -45px;
    padding-top: 30px;
    list-style: none;
    transition: transform 0.3s;
    transition-delay: 0.2s;
  }
  #navbar .top-section .sign-box .user-dropdown .dropdown-section:not(:hover) {
    transform: scaleY(0);
  }
  #navbar .top-section .sign-box .user-dropdown .dropdown-section .user-area {
    position: absolute;
    display: block;
    background-color: #FFFFFF;
    width: 108px;
    height: 139px;
    border: 0.25px solid #D2D2D2;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  #navbar .top-section .sign-box .user-dropdown .dropdown-section li {
    z-index: 100;
    margin-right: 14px;
    margin-top: 10px;
  }
  #navbar .top-section .sign-box .user-dropdown .dropdown-section li:hover {
    color: #2388FF;
    cursor: pointer;
  }
  #navbar .top-section .sign-box .user-dropdown img {
    left: 95px;
    top: 17px;
  }
  #navbar .top-section .sign-box .user-dropdown .open-menu:hover ~ .dropdown-section {
    transform: scaleY(1);
  }
  #navbar .top-section .sign-box .before-user {
    margin-top: 10px;
    margin-left: -40px;
    width: 150px;
  }
  #navbar .top-section .sign-box .before-user .MuiButton-root {
    color: #686868;
    font-weight: 400;
    font-size: 13px;
    line-height: 5px;
    border-color: #686868;
  }
}
#navbar .action-container {
  display: flex;
  align-items: center;
  padding: 5px;
}
@media (max-width: 479px) {
  #navbar .action-container {
    flex-direction: row;
  }
}
#navbar .action-container .user-icon {
  cursor: pointer;
  margin: 14px;
}
@media (max-width: 479px) {
  #navbar .action-container .user-icon {
    width: 24px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  #navbar .action-container .user-icon:hover {
    width: 26px;
  }
}
@media (max-width: 479px) {
  #navbar .action-container .user-icon-login {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 1px;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    font-size: 14px;
    background: #D9E7FF;
    color: #2388ff;
    border-radius: 36px;
  }
}

.menu-section {
  align-items: center;
  display: flex;
  height: 25px;
}
@media (max-width: 479px) {
  .menu-section {
    width: 100%;
    justify-content: space-between;
    margin-left: 12px;
  }
}
.menu-section .logo {
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
  width: 189px;
}
@media (max-width: 479px) {
  .menu-section .logo {
    width: 180px;
  }
}
@media (min-width: 821px) {
  .menu-section .logo {
    width: 220px;
  }
}
.menu-section .item-holder {
  display: flex;
  margin-left: 72px;
  margin-top: 2px;
  height: 32px;
}
.menu-section .item-holder .item {
  margin-left: 80px;
  cursor: pointer;
  font-size: 16px;
}
.menu-section .item-holder .item:hover {
  border-bottom: solid 3px #28292B;
  padding-bottom: 6px;
}

.workbook:hover ~ .workbook-specific {
  transform: scaleY(1);
}

.workbook-specific {
  z-index: 100;
  list-style: none;
  position: absolute;
  left: 475px;
  width: 105px;
  height: 86px;
  transition: transform 0.3s;
  transition-delay: 0.2s;
  background: #FFFFFF;
  border: 0.25px solid #D2D2D2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.workbook-specific li {
  margin-top: 20px;
  margin-left: -10px;
  font-size: 13px;
}
.workbook-specific li:hover {
  color: #2388ff;
  cursor: pointer;
}
.workbook-specific:not(:hover) {
  transform: scaleY(0);
}

.drawer .logo {
  margin-top: 32px;
  margin-left: 32px;
  width: 154px;
  height: 20px;
}
.drawer .close-icon {
  float: right;
  padding: 30px;
  padding-left: 30px;
}
.drawer span {
  color: #28292B;
  font-size: 15px;
}
.drawer .item-box {
  cursor: pointer;
  margin-top: 40px;
}
.drawer .item-box span {
  padding-left: 30px;
}
.drawer .item-box .workbook-dropdown {
  position: relative;
}
.drawer .item-box .workbook-dropdown .dropdown-img {
  width: 14px;
  height: 14px;
  position: absolute;
  top: -35px;
  left: 223px;
}
.drawer .item-box .workbook-dropdown .check-down {
  position: absolute;
  top: -20px;
}
.drawer .item-box .workbook-dropdown .dropdown-specific {
  width: 100%;
  list-style: none;
  font-size: 15px;
  background-color: #F5F5F5;
  padding: 20px;
  padding-left: 30px;
}
.drawer .item-box .workbook-dropdown .dropdown-specific li {
  margin-top: 11px;
  margin-bottom: 11px;
}

#footer {
  margin: 0px;
  background-color: #1E2833;
  color: white;
  padding: 100px;
  padding-bottom: 40px;
  padding-top: 30px;
  position: relative;
  z-index: 99;
}
#footer .limit-section {
  margin: auto;
}
@media (max-width: 479px) {
  #footer {
    padding: 30px;
    height: 422px;
    font-size: 12px;
  }
}
@media (min-width: 768px) and (max-width: 820px) {
  #footer {
    padding: 30px;
    height: 500px;
    font-size: 16px;
  }
}
#footer .responsive-align {
  display: relative;
  color: #C8C8C8;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 820px) {
  #footer .responsive-align .family-dropdown {
    position: absolute;
    top: 250px;
  }
  #footer .responsive-align .family-dropdown .footer-button {
    display: flex;
    position: relative;
    width: 135px;
    height: 40px;
    border: 1px solid #A5A8B7;
    border-radius: 0px;
    color: #C8C8C8;
  }
  #footer .responsive-align .family-dropdown .footer-button .family-text {
    position: absolute;
    top: 12px;
    left: 10px;
  }
  #footer .responsive-align .family-dropdown .footer-button img {
    position: absolute;
    top: 9px;
    width: 24px;
    height: 24px;
    margin-left: 110px;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu {
    margin-top: -0.1px;
    width: 97px;
    background-color: #676C76;
    list-style: none;
    transition: transform 0.3s;
    transition-delay: 0.2s;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu:not(:hover) {
    transform: scaleY(0);
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -30px;
    height: 50px;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li a {
    color: #C8C8C8;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li a:hover {
    color: #FFFFFF;
  }
  #footer .responsive-align .family-dropdown .footer-button:hover ~ .dropdown-menu {
    transform: scaleY(1);
  }
  #footer .responsive-align .family-dropdown .footer-button:hover .after-hover {
    display: block;
  }
  #footer .responsive-align .family-dropdown .footer-button:hover .before-hover {
    display: none;
  }
  #footer .responsive-align .family-dropdown .after-hover {
    display: none;
  }
}
#footer .responsive-align .logo-img {
  margin-top: 4px;
  text-align: center;
}
@media (max-width: 479px) {
  #footer .responsive-align .policy-align {
    position: absolute;
    top: 40%;
  }
  #footer .responsive-align .family-dropdown {
    position: absolute;
    top: 350px;
  }
  #footer .responsive-align .family-dropdown .footer-button {
    display: flex;
    position: relative;
    width: 135px;
    height: 40px;
    border: 1px solid #A5A8B7;
    border-radius: 0px;
    color: #C8C8C8;
  }
  #footer .responsive-align .family-dropdown .footer-button .family-text {
    position: absolute;
    top: 12px;
    left: 10px;
  }
  #footer .responsive-align .family-dropdown .footer-button img {
    position: absolute;
    top: 9px;
    width: 24px;
    height: 24px;
    margin-left: 110px;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu {
    margin-top: -0.1px;
    width: 97px;
    background-color: #676C76;
    list-style: none;
    transition: transform 0.3s;
    transition-delay: 0.2s;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu:not(:hover) {
    transform: scaleY(0);
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -30px;
    height: 50px;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li a {
    color: #C8C8C8;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li a:hover {
    color: #FFFFFF;
  }
  #footer .responsive-align .family-dropdown .footer-button:hover ~ .dropdown-menu {
    transform: scaleY(1);
  }
  #footer .responsive-align .family-dropdown .footer-button:hover .after-hover {
    display: block;
  }
  #footer .responsive-align .family-dropdown .footer-button:hover .before-hover {
    display: none;
  }
  #footer .responsive-align .family-dropdown .after-hover {
    display: none;
  }
}
@media (min-width: 821px) {
  #footer .responsive-align {
    margin-bottom: -40px;
    margin-top: -90px;
  }
  #footer .responsive-align .title {
    width: 644px;
    margin-left: 40px;
    color: #FFFFFF;
  }
  #footer .responsive-align .family-dropdown {
    margin-left: 240px;
    margin-top: 110px;
  }
  #footer .responsive-align .family-dropdown .footer-button {
    display: flex;
    position: relative;
    width: 208px;
    height: 50px;
    border: 1px solid #A5A8B7;
    border-radius: 0px;
    color: #C8C8C8;
  }
  #footer .responsive-align .family-dropdown .footer-button .family-text {
    position: absolute;
    top: 12px;
    left: 10px;
  }
  #footer .responsive-align .family-dropdown .footer-button img {
    position: absolute;
    top: 12px;
    width: 24px;
    height: 24px;
    margin-left: 175px;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu {
    margin-top: -0.1px;
    width: 170px;
    background-color: #676C76;
    list-style: none;
    transition: transform 0.3s;
    transition-delay: 0.2s;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu:not(:hover) {
    transform: scaleY(0);
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -30px;
    height: 50px;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li a {
    color: #C8C8C8;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
  }
  #footer .responsive-align .family-dropdown .dropdown-menu li a:hover {
    color: #FFFFFF;
  }
  #footer .responsive-align .family-dropdown .footer-button:hover ~ .dropdown-menu {
    transform: scaleY(1);
  }
  #footer .responsive-align .family-dropdown .footer-button:hover .after-hover {
    display: block;
  }
  #footer .responsive-align .family-dropdown .footer-button:hover .before-hover {
    display: none;
  }
  #footer .responsive-align .family-dropdown .after-hover {
    display: none;
  }
}
@media (max-width: 479px) {
  #footer .responsive-align {
    flex-direction: column;
    align-items: flex-start;
  }
}
#footer .logo {
  width: 188px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (min-width: 821px) {
  #footer .logo {
    flex: 1;
  }
}
#footer .title {
  font-size: 24px;
}
@media (max-width: 479px) {
  #footer .title {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 820px) {
  #footer .title {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 30px;
  }
}
#footer .button-text {
  font-size: 24px;
  font-weight: 700;
}
#footer hr {
  width: auto;
  border-color: #A5A8B7;
}
@media (max-width: 479px) {
  #footer .company-content {
    position: absolute;
    margin-top: 10%;
    font-size: 12px;
    font-weight: 400;
    color: #C8C8C8;
  }
}
#footer .company-content {
  font-size: 12px;
  font-weight: 400;
  color: #C8C8C8;
}
#footer .content {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
#footer .expanded {
  flex: 1;
}
@media (max-width: 479px) {
  #footer .expanded {
    position: absolute;
    top: 250px;
  }
}
@media (min-width: 768px) and (max-width: 820px) {
  #footer .expanded {
    position: absolute;
    top: 150px;
  }
}
#footer .icon {
  padding: 6px;
  width: 24px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 479px) {
  #footer .policy-align {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17%;
  }
  #footer .policy-align .icon-row {
    display: flex;
  }
  #footer .policy-align .icon-row .icon {
    width: 24px;
    height: 24px;
  }
  #footer .policy-align .icon-row .blog {
    margin-top: 2px;
  }
  #footer .policy-align .icon-row .blog:hover {
    content: url("/public/images/misc/blogHoverIcon.png");
  }
  #footer .policy-align .icon-row .youtube:hover {
    content: url("/public/images/misc/youtubeHoverIcon.png");
  }
  #footer .policy-align .icon-row .kakao:hover {
    content: url("/public/images/misc/kakaoHoverIcon.png");
  }
}
#footer .policy-align {
  min-width: 330px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#footer .policy-align .icon-row {
  display: flex;
}
#footer .policy-align .icon-row .icon {
  width: 24px;
  height: 24px;
}
#footer .policy-align .icon-row .blog {
  margin-top: 2px;
}
#footer .policy-align .icon-row .blog:hover {
  content: url("/public/images/misc/blogHoverIcon.png");
}
#footer .policy-align .icon-row .youtube:hover {
  content: url("/public/images/misc/youtubeHoverIcon.png");
}
#footer .policy-align .icon-row .kakao:hover {
  content: url("/public/images/misc/kakaoHoverIcon.png");
}

.limit-section {
  position: relative;
  max-width: 1200px;
  width: 100%;
}/*# sourceMappingURL=Navbar.css.map */