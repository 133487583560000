#toss-loading-screen {
  margin: auto;
  text-align: center;
}

#toss-result-screen {
  width: 600px;
  margin: auto;
  text-align: center;
}
@media (max-width: 479px) {
  #toss-result-screen {
    width: 200px;
  }
}
#toss-result-screen .ipad-terms {
  color: #F67F68;
}
#toss-result-screen .action-container {
  display: inline;
}
#toss-result-screen .action-container .go-btn {
  border-radius: 8px;
  font-size: 24px;
  width: 452px;
  height: 64px;
  color: white;
}

#toss-fail-screen {
  width: 600px;
  margin: auto;
  text-align: center;
}
@media (max-width: 479px) {
  #toss-fail-screen {
    width: 200px;
  }
}/*# sourceMappingURL=TossResult.css.map */