@import 'MyPage';
@import 'Global';

#study-main {
  @include common-header;
  @include mobile {
    h3 {
      text-align: center;
    }
  }
  hr {
    background-color: #dee2ed;
    height: 1px;
    border: 0; 
    margin: 0px;
  }
  .tab {
    font-size: 20px;
    font-weight: 700;
    &.Mui-selected {
      background-color: #F3F9FF;
    }
  }

  .item-row {
    display: flex;
    align-items: center;
    height: 60px;
    margin-left: 7%;

    .section {
      width: 100px;
    } 
    .title {
      flex: 1
    }
    .btn-area {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 20px 18px 20px 12px;
      gap: 10px;
      position: absolute;
      width: 40px;
      height: 50px;
      background-color: #FFFFFF;
      border: 0.25px solid #D2D2D2;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      .btn-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 20px;
        width: 40px;
        height: 46px;
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 100%;
        li {
          display: flex;
          align-items: center;
          width: 23px;
          height: 13px;
        }
        li:hover {
          color: $cSecondary;
          cursor: pointer;
        }
      }
      
    }
    .btn-area-s {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 15px 18px 8px 12px;
      gap: 10px;
      position: absolute;
      width: 40px;
      height: 25px;
      background-color: #FFFFFF;
      border: 0.25px solid #D2D2D2;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      .btn-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 20px;
        width: 40px;
        height: 46px;
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 100%;
        li {
          display: flex;
          align-items: center;
          width: 23px;
          height: 13px;
        }
        li:hover {
          color: $cSecondary;
          cursor: pointer;
        }
      }
      
    }
    .play-icon {
      width: 60px;
      .icon {
        stroke: grey;
        &:hover {
          cursor: pointer;
          stroke: #2388FF;
        }
      }
    }
    .file-icon {
      width: 60px;
      .icon {
        fill: grey;
        &:hover {
          cursor: pointer;
          fill: #2388FF;
        }
      }
    }
  }

}