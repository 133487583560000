@import 'Global';

#admin-main {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  
  .menu-container {
    display: flex;
    width: 250px;
    margin-top: 100px;
    .greet-message {
      margin-top: 35px;
    }
    .menu-pane {
      margin-top: 50px;
      padding-right: 40px;
      position: sticky;
      position: -webkit-sticky;
      top: 100px;
      height: 30px;
      hr {
        background-color: #dee2ed;
        height: 1px;
        border: 0; 
        margin: 0px;
      }
      .menu-list {
        font-size: 17px;
      }
      .menu-btn {
        font-size: 18px;
        color: $cBlack;
        justify-content: flex-start;
        padding: 10px;
      }
      .logout-btn {
        font-size: 18px;
        color: #f67f68;
        justify-content: flex-start;
      }
      .selected {
        background-color: rgba(23,88,255, 0.05);
        color: #2388ff;
        font-weight:900;

      }
    }
    .content-container {
      position: absolute;
      right: 0px;
      margin: 20px;
    }
  }
}

#mypage-main {
  display: flex;
  justify-content: center;
  margin: auto;
  @include desktop {
    max-width: 1080px;
  }
  width: 100%;
  .menu-container {
    width: 250px;
    
    .greet-message {
      margin-top: 35px;
    }
    .menu-pane {
      margin-top: 50px;
      padding-right: 40px;
      position: sticky;
      position: -webkit-sticky;
      top: 100px;
      height: 30px;
      hr {
        background-color: #dee2ed;
        height: 1px;
        border: 0; 
        margin: 0px;
      }
      .menu-list {
        font-size: 17px;
      }
      .menu-btn {
        font-size: 18px;
        color: $cBlack;
        justify-content: flex-start;
        padding: 10px;
      }
      .logout-btn {
        font-size: 18px;
        color: #f67f68;
        justify-content: flex-start;
      }
      .selected {
        background-color: rgba(23,88,255, 0.05);
        color: #2388ff;
        font-weight:900;

      }
    }
  }
  .content-container {
    @include desktop {
      flex: 1;
      margin: 20px;
      max-width: 1000px;
      min-height: 1600px;
    }
  }
}

.drawer-container{
  span { 
    color: black;
    font-size: 15px;
  }
  .close-icon {
    padding: 30px;
    float: right;
  }
  .item-box {
    cursor: pointer;
    padding: 20px;
    padding-left: 30px;
  }

  .logout-btn {
    margin-top: 10px;
    font-size: 15px;
    color: #f67f68;
    justify-content: flex-start;
    padding-left: 30px;
  }

  img {
    margin-left: 30px;
  }

  .user-name {
    margin-left: 10px;
    position: absolute;
    margin-top: 10px;
    color: $cDarkGray;
  }
}



@mixin common-header {
  hr {
    background-color: #dee2ed;
    height: 1px;
    border: 0; 
    margin-bottom: 24px;
  }
  h3 {
    color:$cBlack;
  }
  .gray {
    color: $cGray
  }
}