@import 'Global';

hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0; 
  margin-bottom: 24px;
}

.faq-subquestion {
  width: 95%;
  margin: auto;
  hr {
    margin:0;
    padding:0;
  }
  span { 
    white-space:pre-line;
  }
}

#mypage-faq {
  background: white;
  @include desktop {
    max-width:760px;
  }
  @include mobile {
    margin: 24px;
    h3 {
      text-align: center;
    }
  }
}