@import 'Global';

#navbar {
  border-bottom: solid 1px #E4E8F4;
  background-color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: top 0.3s;
  left: 0;
  right: 0;
  top: 0;
  height: $nav-height;
  z-index: 500;
  height: 112px;
  @include mobile {
    height: $nav-height-mobile;
    flex-direction: row;
    height: 80px;
    .logo-box {
      padding: 5px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-left:12px;
      width: 100%;
      
      .logo {
        object-fit: contain;
        cursor: pointer;
        width: 154px;
      }
      .item-holder {
        flex: 1;
        .item {
          padding: 26px;
          cursor: pointer;
          font-size: 18px;
          &:hover {
            font-weight: 900;
          }
        }
      }
    }
  }
  @include desktop {
    .top-section {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      color: #686868;

      .open-menu {
        font-size: 13px;
        color:#686868;
        span {
          position: absolute;
          top:10px;
          width: 100%;
          left: -20%;
        }
        img {
          position: absolute;
        }
        .family-hover {
          margin-left: 83px;
          margin-top: 17px;
        }
        .family-margin{
          left: 0;
        }
        
      }

      .family-site {  
        z-index: 100;
        float: left;
        position: absolute;
        margin-top: 35px;
        width: 78px;
        height: 86px;
        list-style: none;
        transition: transform 0.3s;
        transition-delay: 0.2s;
        background: #FFFFFF;
        border: 0.25px solid #D2D2D2;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px;

        li {
          margin-top: 20px;
          margin-left: -20px;

          a {
            text-decoration : none;
            color: inherit;
          }
        }
        li:hover {
          color: $cSecondary;
          cursor: pointer;
        }

        &:not(:hover) {
          transform: scaleY(0);
        }
      }
      .open-menu:hover ~ .family-site {
        transform: scaleY(1);
      }

      .open-menu:hover .after-hover {
        display: block;
      }
      .open-menu:hover .before-hover {
        display: none;
      }
      .after-hover {
        display: none;
      }

      .sign-box {
        width: 108px;
        text-align: right;
        position: relative;
        .user-dropdown {
          margin-top:-11px;
          margin-right: 50px;

          .dropdown-section {
            width: 108px;
            margin-left: -45px;
            padding-top: 30px;
            list-style: none;
            transition: transform 0.3s;
            transition-delay: 0.2s;
          
            &:not(:hover) {
              transform: scaleY(0);
            }
            .user-area {
              position: absolute;
              display: block;
              background-color:#FFFFFF;
              width:108px;
              height: 139px;
              border: 0.25px solid #D2D2D2;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
              border-radius: 4px;
            }
            li {
              z-index:100;
              margin-right: 14px;
              margin-top:10px;
            }
            li:hover {
              color: #2388FF;
              cursor: pointer;
            }
          }
          img {
            left: 95px;
            top: 17px;
          }
        
          .open-menu:hover ~ .dropdown-section {
            transform: scaleY(1);
          }
        }
        .before-user {
          margin-top: 10px;
          margin-left: -40px;
          width: 150px;
          .MuiButton-root {
            color: #686868;
            font-weight: 400;
            font-size: 13px;
            line-height: 5px;
            border-color: #686868;
          }
        }
      }
    }
  }
  .action-container {
    display: flex;
    align-items: center;
    padding: 5px;
    @include mobile {
      flex-direction: row;
    }
    
    .user-icon {
      cursor: pointer;
      margin: 14px;
      // margin-left: 36px;
      @include mobile {
        width: 24px;
        object-fit: contain;
        &:hover {
          width: 26px;
        }
      }
    }
    .user-icon-login {
      @include mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 1px;
        margin-right:10px;
        width: 32px;
        height: 32px;
        font-size: 14px;
        background: #D9E7FF;
        color: $cSecondary;
        border-radius: 36px;
      }
    }
  }
}

.menu-section {
  align-items: center;
  display: flex;
  height: 25px;
  @include mobile {
    width: 100%;
    justify-content: space-between;
    margin-left:12px;
  }
  .logo {
    object-fit: contain;
    cursor: pointer;
    width: 189px;
    @include mobile {
      width: 180px;
    }
    @include desktop {
      width: 220px;
    }
  }
  .item-holder {
    display: flex;
    margin-left: 72px;
    margin-top: 2px;
    height: 32px;
    .item {
      margin-left: 80px;
      cursor: pointer;
      font-size: 16px;
      &:hover {
        border-bottom: solid 3px #28292B;
        padding-bottom: 6px;
      }
    }
  }
}

.workbook:hover ~ .workbook-specific {
  transform: scaleY(1);
  }

.workbook-specific {
  z-index: 100;
  list-style: none;
  position: absolute;
  left: 475px;
  width: 105px;
  height: 86px;
  transition: transform 0.3s;
  transition-delay: 0.2s;
  background: #FFFFFF;
  border: 0.25px solid #D2D2D2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  li {
    margin-top: 20px;
    margin-left: -10px;
    font-size: 13px;
  }

  li:hover {
    color: $cSecondary;
    cursor: pointer;
  }

  &:not(:hover) {
    transform: scaleY(0);
  }
}


// not applicable inside #navbar
.drawer{
  .logo {
    margin-top: 32px;
    margin-left: 32px;
    width: 154px;
    height: 20px;
  }
  .close-icon {
    float: right;
    padding: 30px;
    padding-left: 30px;
  }
  
  span { 
    color: $cBlack;
    font-size: 15px;
  }

  .item-box {
    cursor: pointer;
    margin-top: 40px;
    span {
      padding-left: 30px;
    }
    .workbook-dropdown {
      position: relative;
      .dropdown-img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: -35px;
        left: 223px;
      }
      .check-down {
        position: absolute;
        top: -20px;
      }
      .dropdown-specific {
        width: 100%;
        list-style: none;
        font-size: 15px;
        background-color:#F5F5F5;
        padding: 20px;
        padding-left: 30px;
        li {
          margin-top: 11px;
          margin-bottom: 11px;
        }
      }
    }
  }
}

#footer {
  margin: 0px;
  background-color: #1E2833;
  color: white;
  padding: 100px;
  padding-bottom: 40px;
  padding-top: 30px;
  position: relative;
  z-index: 99;

  .limit-section {
    margin: auto;
  }

  @include mobile {
    padding: 30px;
    height: 422px;
    font-size: 12px;
  }
  @include tablet {
    padding: 30px;
    height: 500px;
    font-size: 16px;
  }

  .responsive-align {
    display: relative;
    color: #C8C8C8;
    display: flex;
    align-items: center;
    @include tablet {
      .family-dropdown {
        position: absolute;
        top: 250px;
        .footer-button { 
          display: flex;
          position :relative;
          width: 135px;
          height: 40px;
          border: 1px solid #A5A8B7;
          border-radius: 0px;
          color: #C8C8C8;
          .family-text {
            position: absolute;
            top: 12px;
            left: 10px;
          }
          img {
            position: absolute;
            top:9px;
            width: 24px;
            height: 24px;
            margin-left: 110px;
          }
        }
    
        .dropdown-menu {
          margin-top:-0.1px;
          width: 97px;
          background-color: #676C76;
          list-style: none;
          transition: transform 0.3s;
          transition-delay: 0.2s;
          &:not(:hover) {
            transform: scaleY(0);
          }
          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left:-30px;
            height: 50px;
            a {
              color: #C8C8C8;
              text-decoration-line: none;
            }
            a:hover {
              color:#FFFFFF;
            }
          }
        }
        .footer-button:hover ~ .dropdown-menu {
          transform: scaleY(1);
        }
        .footer-button:hover .after-hover {
          display: block;
        }
        .footer-button:hover .before-hover {
          display: none;
        }
        .after-hover {
          display: none;
        }
      }
    }
    .logo-img {
      margin-top: 4px;
      text-align: center;
    }
    @include mobile {
    .policy-align{
      position: absolute;
      top: 40%;
    }
    .family-dropdown {
      position: absolute;
      top: 350px;
      .footer-button { 
        display: flex;
        position :relative;
        width: 135px;
        height: 40px;
        border: 1px solid #A5A8B7;
        border-radius: 0px;
        color: #C8C8C8;
        .family-text {
          position: absolute;
          top: 12px;
          left: 10px;
        }
        img {
          position: absolute;
          top:9px;
          width: 24px;
          height: 24px;
          margin-left: 110px;
        }
      }
  
      .dropdown-menu {
        margin-top:-0.1px;
        width: 97px;
        background-color: #676C76;
        list-style: none;
        transition: transform 0.3s;
        transition-delay: 0.2s;
        &:not(:hover) {
          transform: scaleY(0);
        }
        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left:-30px;
          height: 50px;
          a {
            color: #C8C8C8;
            text-decoration-line: none;
          }
          a:hover {
            color:#FFFFFF;
          }
        }
      }
      .footer-button:hover ~ .dropdown-menu {
        transform: scaleY(1);
      }
      .footer-button:hover .after-hover {
        display: block;
      }
      .footer-button:hover .before-hover {
        display: none;
      }
      .after-hover {
        display: none;
      }
    }
  }
    @include desktop {
    margin-bottom: -40px;
    margin-top: -90px;
    .title {
      width: 644px;
      margin-left: 40px;
      color: #FFFFFF;
    }
    
    .family-dropdown {
      margin-left: 240px;
      margin-top: 110px;
      .footer-button { 
        display: flex;
        position :relative;
        width: 208px;
        height: 50px;
        border: 1px solid #A5A8B7;
        border-radius: 0px;
        color: #C8C8C8;
        .family-text {
          position: absolute;
          top: 12px;
          left: 10px;
        }
        img {
          position: absolute;
          top:12px;
          width: 24px;
          height: 24px;
          margin-left: 175px;
        }
      }
  
      .dropdown-menu {
        margin-top:-0.1px;
        width: 170px;
        background-color: #676C76;
        list-style: none;
        transition: transform 0.3s;
        transition-delay: 0.2s;
        &:not(:hover) {
          transform: scaleY(0);
        }
        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left:-30px;
          height: 50px;
          a {
            color: #C8C8C8;
            text-decoration-line: none;
          }
          a:hover {
            color:#FFFFFF;
          }
        }
      }
      .footer-button:hover ~ .dropdown-menu {
        transform: scaleY(1);
      }
      .footer-button:hover .after-hover {
        display: block;
      }
      .footer-button:hover .before-hover {
        display: none;
      }
      .after-hover {
        display: none;
      }
      
    }
  }
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .logo {
    width: 188px;
    object-fit: contain;
    @include desktop {
      flex: 1;
    }
  }
  .title {
    font-size: 24px;

    @include mobile {
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 30px;
    }
    @include tablet {
      font-size: 16px;
      padding-top: 10px;
      padding-left: 30px;
    }
  }
  .button-text {
    font-size: 24px;
    font-weight: 700;
  }
  
  hr {
    width: auto;
    border-color: #A5A8B7;
  }

  @include mobile{
    .company-content {
      position: absolute;
      margin-top: 10%;
      font-size: 12px;
      font-weight: 400;
      color:#C8C8C8;
    }
  }
  .company-content {
    font-size: 12px;
    font-weight: 400;
    color:#C8C8C8;
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  .expanded {
    @include mobile {
      position: absolute;
      top: 250px;
    }
    @include tablet {
      position: absolute;
      top: 150px
    }
    flex: 1;
  }

  .icon {
    padding: 6px;
    width: 24px;
    object-fit: contain;
  }
  @include mobile{
    .policy-align {
      min-width: 100%;
      // background-color: yellow;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 17%;
      .icon-row {
        display: flex;
        .icon {
          width: 24px;
          height: 24px;
        }
        .blog {
          margin-top: 2px;
        }
        .blog:hover {
          content: url('/public/images/misc/blogHoverIcon.png'); 
        }
        .youtube:hover {
          content: url('/public/images/misc/youtubeHoverIcon.png');
        }
        .kakao:hover {
          content: url('/public/images/misc/kakaoHoverIcon.png');
        }
      }
  }
  }
  .policy-align {
    min-width: 330px;
    // background-color: yellow;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-row {
      display: flex;
      .icon {
        width: 24px;
        height: 24px;
      }
      .blog {
        margin-top: 2px;
      }
      .blog:hover {
        content: url('/public/images/misc/blogHoverIcon.png'); 
      }
      .youtube:hover {
        content: url('/public/images/misc/youtubeHoverIcon.png');
      }
      .kakao:hover {
        content: url('/public/images/misc/kakaoHoverIcon.png');
      }
    }
  }
}

.limit-section {
  position: relative;
  max-width: 1200px;
  width: 100%;
}