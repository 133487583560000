@import 'MyPage';
@import 'Global';


#kimini-main{
  @include common-header;
  @include mobile {
    margin: 24px;
    .kimini-link {
      text-align: center;
    }
  }


  .kimini-classroom {
    text-align: center;
  }

  .introduction-title {
    font-weight: 700;
  }

  ol {
    list-style: none;
  }

  .kimini-introduction {
    font-weight: 400;
    font-size: 16px;
    li {
      margin-left: -20px;
    }
  }

  .kimini-button {
    height: 56px;
    font-size: 18px;
  }

  .kimini-qualification {
    @include mobile {
      width: 100%;
    }
  }
}