@import 'Global';


#intro-screen {
  img {
    image-rendering: -webkit-optimize-contrast;
  }
  .section { 
    background-color: white;
    @include desktop {
      position: relative;
      z-index: 1;
      padding: 0px;
      margin: 0px;
    }
    .swiper-pagination-bullets {
      display: none;
    }

    .slide-img {
      display: flex;
      text-align: center;
    }

    @include mobile {
      color:$cBlack;
      text-align: center;

      .header {
        font-size: 24px;
        font-weight: 700;
      }

      .mobile-section {
        width:100%;
        align-items: center;
        
      }
      
    }

    &.main1 {
      background-color: $cLightGray;
      width: 100%;
      .limit-img {
        margin:auto;
        max-width:1440px;
      }
      @include mobile {
        height:1400px;
        margin: auto;
        background: #F7F7F8;
        .normal {
          font-size: 15px;
          // font-weight: 700;
        }
        span {
          color: $cBlack;
          font-size: 24px;
          font-weight: 700;
          &.normal {
            font-size: 15px;
            font-weight: 700;
          }
        }
      }
    }
    &.blog-review {
      background-color: #FFFFFF;
      width: 100%;
      .limit-img {
        margin:auto;
        max-width:1440px;
      }
      .review-btn {
        width: 286px;
        height: 64px;
        font-size: 24px;
        @include mobile {
          width: 170px;
          height: 32px;
          font-size: 14px;

        }
      }
      @include mobile {
        // height:500px;
        margin: auto;
        background: #F7F7F8;
        span {
          color: $cBlack;
          font-size: 24px;
          font-weight: 700;
        }
      }
    }

    &.main-add {
      background-color: $cNavy;
      width: 100%;
      .limit-section {
        position: relative;
        max-width: 1080px;
        padding-right:40px;
        .main-add-text {
          position: absolute;
          left: 14%;
          margin-top: 160px;
          margin-bottom: 80px;
          width: 70%;
        }
        .gift-img {
          padding-top: 290px;
          display: flex; 
          justify-content: space-between;
          padding-bottom: 160px;
          .gift-img-specific {
            width: 30%;
          }
        }
      }
      @include mobile {
        color:white;
        font-weight: 700;
        font-size: 24px;
      }
    }

    &.main2 {
      @include desktop {
        background: url('/public/images/mainPage/introMain2Background.png') no-repeat;
        background-size: cover;
        width: 100%;
        height: 1128px;
        .limit-section {
          margin: auto; 
          max-width: 1080px;
          padding-right:40px;
          .ranking-img {
            display: flex; 
            justify-content: space-between;
            .ranking-img-specific {
              width: 30%;
            }
          }
          .ranking-text {
            text-align: center;
          
            .purchase-btn {
              width: 424px;
              height: 80px;
              font-size: 24px;
            }
            span {
              font-size: 18px;
            }
          }
          span {
            display : flex;
            justify-content : center;
           
          }
        }
      }
      @include mobile {
        background:url('/public/images/mainPageMobile/main2Background_m.svg') no-repeat;
        background-size: cover;
        span {
          color: $cBlack;
          font-weight: 700;
          font-size: 24px;
          span{
            color:$cBlack;
            font-size: 14px;
            background: #FDFF88;
            padding: 6px 14px;
            font-weight: normal;

          }
        }
      }
      
    }

    &.main3 {
      @include desktop {
      background-color: $cLightGray;
      }
      @include mobile {
        .title {
          font-size: 16px;
          font-weight: 700;
        }
        .text {
          font-size: 14px;
        }
      }
    }

    &.main4 {
      background-color: $cBlack;
      .limit-img {
        margin:auto;
        max-width:1440px;
      }
      @include mobile {

      }
    }

    &.main5 {
      background-color: white;
      .limit-section {
        position: relative;
        z-index: 1;
        padding: 0px;
        margin: auto;
        max-width: 1440px;
        }
    }

    &.main6 {
      background-color: $cLightGray;
      position: relative;
      z-index: 1;
      padding: 0px;
      margin: auto;
      max-width: 1440px;
    }
    
    &.main7 {
      @include desktop {
        background-color: #39445F;
      }
      @include mobile {
        background-color: $cLightGray;
      }
    }

    &.main8 {
      @include mobile {
        background-color: $cNavy;
      }
    }

    &.main9 {
      .limit-section {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .orgel-video {
        position: absolute;
        top: 25%;
      }
    }

    &.main10 {
      @include desktop{
        background-color: $cBlack;
        margin: auto;
        .img-bg {
          margin-bottom: -150px;
        }
        // 오면 영상 사각형 및 회색 사각형 제거
        .orgel-video {
          position: absolute;
          width: 80%;
          top: 37%;
          left: 10%;
          z-index: 100;
          border-radius: 12px;
        }
        .orgel-text {
          position: absolute;
          display: block;
          margin: auto;
          top: 90%;
          justify-content: center;
          align-items: center;
          left: 42%;
        }
        .orgel-link {
          display: block; 
          margin: auto;
          z-index: 100;
        }
        .figure-link {
          display: block;
          position: absolute;
          width: 27%;
          justify-content: center;
          align-items: center;
          left: 36.3%;
          top: 32%;
        }
      }
      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .orgel-video {
          position: absolute;
          top: 17%;
          width: 90%;
          border-radius: 0px;
          
        }
      }
    }

    &.main11 {
      background-color: $cBlack;
      @include desktop {
        .purchase-btn {
          width: 380px;
          height: 80px;
          font-size: 24px;
        }
      }
      @include mobile{
        position: relative;
        .front-video {
          position: absolute;
          margin-top: 7%;
          height: 26%;
          width: auto;
          top: 40%;
          left: 9%;
          z-index: 100;
          border-radius: 12px;
        }
        .figure-link {
          display: block;
          position: absolute;
          width: 80%;
          justify-content: center;
          align-items: center;
          left: 10%;
          top: 38%;
        }
      }
  }

    &.main12 {
      background-color: $cLightGray;
      @include mobile {
        position: relative;
        background-color: $cBlack;
        .pretty-btn {
          position: absolute;
          left:16%;
          right:16%;
          top: 600px;
          padding: 20px;
          padding-left: 50px;
          padding-right: 50px;
          font-size: 18px;
          z-index: 10;
        }
      }
    }

    &.main13 {
      @include mobile {
        .MuiCollapse-root {
          text-align: left;
        }
      }
    }
  }
  .limit-section {
    margin: auto;
    max-width:1440px;
  }
  
  .faq-section {
    background:white;
    h1 {
      padding-top: 160px;
      padding-bottom: 160px;
      font-size: 64px;
      font-weight: 900;
      color: $cBlack;
      text-align: center;
    }
    .faq {
      margin: auto;
      max-width: 1080px;
      padding-bottom: 170px;
    }
  }
  .intro-slider {
    .carousel .control-dots .dot {
      border-radius: 0%;
      width: 30px;
      height: 5px;
      margin-bottom: 16px;
    }
    @include mobile {
      background-color:$cBlack;
      .slick-slider {
        touch-action: auto;
        -ms-touch-action: auto;
      }
      .back-video {
        width:100%;
      }
      .front-video {
        position: absolute;
        height: auto;
        width: 60%;
        top:35%;
        right: 20%;
        border-radius:12px;
        z-index: 100;
      }
      .orgel-video {
        position: absolute;
        width: 1%;
        height: 1%;
        left: calc(50% - 1080px/2);
        top: 550px;
        z-index: 100;
        border-radius: 12px;

      }
      .title-container {
        // text-align: left;
        // color: white;
        // font-weight: 700;
        // font-size: 24px;
        // margin-left: 10%;
        width:100%;
        height: 60px;
        margin-top:24px;
        .title {
          color: white;
          font-weight:700;
          font-size: 26px;
          text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
          2px 5px 5px rgba(0, 0, 0, 0.25),
          2px -2px 2px rgba(0, 0, 0, 0.25),
          -2px -2px 2px rgba(0, 0, 0, 0.5);
        }
      }
    }
    @include desktop {
      .back-video {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        z-index: -1;
      }
      .front-video {
        position: absolute;
        width: 40%;
        height: auto;
        top: 20%;
        right: 10%;
        // left: calc(50% - 486px/2);
        z-index: 100;
        border-radius: 10px;

        // position: absolute;
        // width: 486px;
        // left: calc(50% - 486px/2);
        // top: 0%;
        // bottom: 0%;
        // z-index: 100;
      }
      .intro-image{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0%;
        z-index: 100;
      }
      .intro-text {
        position: absolute;
        top: 30%;
        width: 20%;
        right: 10%;
      }
      .title-container {
        text-align: left;
        color: white;
          position: absolute;
          left: 10%;
          top: 30%;
          .title {
            font-size: 3.1em;
            
          }
          .sub-title {
            font-size: 2.2em;
          }  
        }
      }
    }
    
  .img-bg {
    width: 100%;
    background-color: white;
    display: block;
  }
  .popup-box {
    position: absolute;
    // background-color: yellow;
    z-index: 3;
  }
  .popup-text {
    object-fit: contain;
    width: 100%;
    max-width: 1080px;
    z-index: 3;
  }

  .download-app {
    width: 100%;
    @include desktop {
      margin-top:-160px;
      margin-bottom: 120px;
    }
    @include mobile {
      margin-top:-200px;
      margin-bottom: 80px;
    }
  }
  
}

#underline-box {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  width: 100%;
  height:104px;

  display: flex;
  background: rgba(0,0,0,0.8);
  justify-content: center;
  align-items: center;
  text-align: center;

  @include mobile {
    flex-direction: column;
    height: 150px;
  }
  span {
    font-size: 22px;
    margin: 10px;
    // background: -webkit-linear-gradient(left, $cLeftGrad, $crightGrad);
    // background: linear-gradient(to right,#FDFF95,#4DFF94 15%,#47DFFD 35%, #9FB4FF 50%,#FF9BE9 65%,#FF9F47 85%,#FDFF95);
    background: linear-gradient(to right, #4DFF94, #47DFFD 15%, #FF9BE9 35%, #4DFF94 50%, #47DFFD 65%, #FF9BE9 85%, #4DFF94);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    animation: gradient 3s linear infinite;
    background-size: 200% 100%;

    @include mobile {
      font-size: 18px;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0;
    }
    100% {
      background-position: 100%;
    }
  }
  .close-btn {
    position: absolute;
    top: -30%;
    right: 20%;
    @include mobile {
      top: 0;
      right: 0;
    }
  }
  .custom-btn {
    padding: 10px;
    @include mobile {
      padding: 15px;
    }
  }
}

.pretty-btn {
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
/* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 10px #eee;
  border-radius: 10px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $cLeftGrad 0%, $cRightGrad 50%, $cLeftGrad 100%);
  cursor: pointer;

  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: $cBlack;
  }

  // btn text
  font-size: 24;
  color: #222222;
  font-weight: 900;
}

