#auth-verify-email .auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#auth-verify-email .auth-options h1 {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  #auth-verify-email .auth-options {
    margin-top: 100px;
    width: 452px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  #auth-verify-email .auth-options {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
  }
}
#auth-verify-email .auth-options .auth-aka-logo {
  cursor: pointer;
  display: block;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 479px) {
  #auth-verify-email .auth-options .auth-aka-logo {
    margin-top: 50px;
  }
}
#auth-verify-email .auth-options .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #auth-verify-email .auth-options .auth-option-field {
    width: 328px;
  }
}
#auth-verify-email .auth-user-email {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 8px;
}
#auth-verify-email .verify-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#auth-verify-email .verify-button .verify-button-divide {
  flex-direction: row;
  margin-top: 40px;
  width: 210px;
  height: 64px;
  font-weight: 700;
  font-size: 24px;
  border-radius: 8px;
}
#auth-verify-email .verify-button .logout-button {
  background-color: #FFFFFF;
  border: 1px solid #2388FF;
  color: #2388FF;
}
#auth-verify-email .resent-verify-email {
  width: 100%;
  margin-top: 40px;
  color: #7E8288;
  text-align: center;
  font-size: 16px;
}/*# sourceMappingURL=AuthVerifyEmailScreen.css.map */