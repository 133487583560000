@import 'Global';

#business-screen {
  background-color: #000000;
  margin: auto;
  .section {
    max-width:1920px;
    text-align: center;
  }

  img {
    max-width: 1080px;
    width: 100%;
  }
  
  .main1 {
    background: url('/public/images/mainPage/businessMain1.svg') no-repeat;
    background-position: center;
    height: 660px;
    @include mobile {
      margin-top:-30px;
    }
  }

  .main2 {
    @include desktop {
      background: url('/public/images/mainPage/businessMain2.svg') no-repeat;
      background-position: center;
      height: 714px;
    }
    @include mobile {
      background-color:#FFFFFF;
      .slider-header {
        font-size: 18px;
        font-weight: 500;
        span {
          font-size: 20px;
        }
      }
      .business-slide {
        margin-top: 39px;

        height: 436px;
        img {
          width: 216px;
          height: 384px;
        }
  
        .swiper-slide:nth-child(2n) {
          margin-top:30px;
        }
      } 
    }
  }

  .main3 {
    @include desktop {
      background-color: #EAEAEA;
      img {
        max-width: 1080px;
        width: 100%;
      }
    }
    @include mobile {
      background: url('/public/images/mainPageMobile/businessMain4_m.svg');
      background-position: center;
      height: 650px;
      .business-textbook {
        img {
          width: 312px;
          height: 288px;
          margin-top: 180px;
        }
        .swiper-button-next {
          margin-top: 100px;
        }
        .swiper-button-prev {
          margin-top: 100px;
        }
      }
    }
  }

  .main4 {
    background-color: #FFFFFF;
    padding-top: 160px;
    padding-bottom: 220px;

    span {
      font-size: 40px;
      font-weight: 500;
      span {
        font-size: 44px;
      }
    }

    .business-slide {
      margin-top: 80px;
      max-width: 1080px;
      height: 700px;

      .swiper-slide:nth-child(2n) {
        margin-top:100px;
      }
    } 
    @include mobile {
      img {
        width: 90%;
        margin: auto;
      }
    }
  }

  .main6 {
    margin: auto;
    height: 1126px;
    max-width: 1080px;
    background: url('/public/images/mainPage/businessMain6.svg') no-repeat;
    background-position: center;
    .business-textbook {
      @include desktop {
        max-width: 900px;
        padding-top: 250px;
        img {
          width: 714px;
          height: 600px;
        }
        .swiper-button-next {
          margin-top: 100px;
        }
        .swiper-button-prev {
          margin-top: 100px;
        }
      }
    }
  }

  .main7 {
    background-color: #FFFFFF;
    padding-bottom: 202px; 
  }

  .main8 {
    background-color: #BBDAFF;
  }

  .main9 {
    background-color: #D5ABFF;
  }


  .swiper-button-next {
    width: 64px;
    height: 64px;
    background: url('/public/images/mainPage/swiperRight.svg') no-repeat;
    @include mobile {
      background-size: cover;
      width: 40px;
      height: 40px;
    }
  }
  .swiper-button-next:hover {
    background: url('/public/images/mainPage/swiperRightHover.svg') no-repeat;
    @include mobile {
      background-size: cover;
      width: 40px;
      height: 40px;
    }
  }
  .swiper-button-prev {
    width: 64px;
    height: 64px;
    background: url('/public/images/mainPage/swiperLeft.svg') no-repeat;
    @include mobile {
      background-size: cover;
      width: 40px;
      height: 40px;
    }
  }
  .swiper-button-prev:hover {
    background: url('/public/images/mainPage/swiperLeftHover.svg') no-repeat;
    @include mobile {
      background-size: cover;
      width: 40px;
      height: 40px;
    }
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
}
