#auth-find-email .auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#auth-find-email .auth-options h1 {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  #auth-find-email .auth-options {
    margin-top: 100px;
    width: 452px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  #auth-find-email .auth-options {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
  }
}
#auth-find-email .auth-options .auth-aka-logo {
  cursor: pointer;
  display: block;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 479px) {
  #auth-find-email .auth-options .auth-aka-logo {
    margin-top: 50px;
  }
}
#auth-find-email .auth-options .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #auth-find-email .auth-options .auth-option-field {
    width: 328px;
  }
}
#auth-find-email .auth-find {
  width: 100%;
}
#auth-find-email .auth-option-namefield {
  display: grid;
  gap: 16px;
}
@media (min-width: 768px) {
  #auth-find-email .auth-option-namefield {
    grid-template-columns: 218px 218px;
  }
}
@media (max-width: 479px) {
  #auth-find-email .auth-option-namefield {
    grid-template-columns: 156px 156px;
    gap: 16px;
  }
}
#auth-find-email .auth-option-namefield .auth-option-field {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
#auth-find-email .auth-option-namefield .auth-option-field .input-text {
  margin-top: 5px;
}
#auth-find-email .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 8px;
}
#auth-find-email .auth-button {
  margin-top: 40px;
  width: 452px;
  height: 64px;
  color: #FFFFFF;
  font-size: 24px;
  border-radius: 8px;
}
@media (max-width: 479px) {
  #auth-find-email .auth-button {
    width: 328px;
  }
}/*# sourceMappingURL=AuthFindEmail.css.map */