#auth-signup .auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#auth-signup .auth-options h1 {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  #auth-signup .auth-options {
    margin-top: 100px;
    width: 452px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  #auth-signup .auth-options {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
  }
}
#auth-signup .auth-options .auth-aka-logo {
  cursor: pointer;
  display: block;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 479px) {
  #auth-signup .auth-options .auth-aka-logo {
    margin-top: 50px;
  }
}
#auth-signup .auth-options .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #auth-signup .auth-options .auth-option-field {
    width: 328px;
  }
}
#auth-signup .auth-signup-namefield {
  display: grid;
  gap: 16px;
}
@media (min-width: 768px) {
  #auth-signup .auth-signup-namefield {
    grid-template-columns: 218px 218px;
  }
}
@media (max-width: 479px) {
  #auth-signup .auth-signup-namefield {
    grid-template-columns: 156px 156px;
  }
}
#auth-signup .auth-signup-field {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
}
#auth-signup .auth-signup-field .input-text {
  margin-top: 5px;
}
#auth-signup .auth-signup-field span span {
  color: #F67F68;
}
#auth-signup .check-button {
  float: left;
  align-items: center;
  width: 100%;
}
#auth-signup .check-button label span {
  color: #FF7155;
  padding-left: 5px;
}
#auth-signup .check-button .show-terms {
  float: right;
  cursor: pointer;
  margin-top: 3px;
}
#auth-signup .terms {
  margin-top: 16px;
  margin-bottom: 40px;
  width: 100%;
  color: #7E8288;
}
@media (min-width: 768px) {
  #auth-signup .terms {
    padding-left: 60px;
  }
}
#auth-signup .submit {
  margin-bottom: 120px;
  width: 100%;
}
#auth-signup .submit .auth-button {
  margin-top: 60px;
  height: 64px;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  border-radius: 8px;
}/*# sourceMappingURL=AuthSignup.css.map */