#mypage-main {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}
@media (min-width: 768px) {
  #mypage-main {
    max-width: 1080px;
  }
}
#mypage-main .menu-container {
  width: 250px;
}
#mypage-main .menu-container .greet-message {
  margin-top: 35px;
}
#mypage-main .menu-container .menu-pane {
  margin-top: 50px;
  padding-right: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  height: 30px;
}
#mypage-main .menu-container .menu-pane hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin: 0px;
}
#mypage-main .menu-container .menu-pane .menu-list {
  font-size: 17px;
}
#mypage-main .menu-container .menu-pane .menu-btn {
  font-size: 18px;
  color: #28292B;
  justify-content: flex-start;
  padding: 10px;
}
#mypage-main .menu-container .menu-pane .logout-btn {
  font-size: 18px;
  color: #f67f68;
  justify-content: flex-start;
}
#mypage-main .menu-container .menu-pane .selected {
  background-color: rgba(23, 88, 255, 0.05);
  color: #2388ff;
  font-weight: 900;
}
@media (min-width: 768px) {
  #mypage-main .content-container {
    flex: 1;
    margin: 20px;
    max-width: 1000px;
    min-height: 1600px;
  }
}

.drawer-container span {
  color: black;
  font-size: 15px;
}
.drawer-container .close-icon {
  padding: 30px;
  float: right;
}
.drawer-container .item-box {
  cursor: pointer;
  padding: 20px;
  padding-left: 30px;
}
.drawer-container .logout-btn {
  margin-top: 10px;
  font-size: 15px;
  color: #f67f68;
  justify-content: flex-start;
  padding-left: 30px;
}
.drawer-container img {
  margin-left: 30px;
}
.drawer-container .user-name {
  margin-left: 10px;
  position: absolute;
  margin-top: 10px;
  color: #7E8288;
}

#kimini-main hr {
  background-color: #dee2ed;
  height: 1px;
  border: 0;
  margin-bottom: 24px;
}
#kimini-main h3 {
  color: #28292B;
}
#kimini-main .gray {
  color: #979797;
}
@media (max-width: 479px) {
  #kimini-main {
    margin: 24px;
  }
  #kimini-main .kimini-link {
    text-align: center;
  }
}
#kimini-main .kimini-classroom {
  text-align: center;
}
#kimini-main .introduction-title {
  font-weight: 700;
}
#kimini-main ol {
  list-style: none;
}
#kimini-main .kimini-introduction {
  font-weight: 400;
  font-size: 16px;
}
#kimini-main .kimini-introduction li {
  margin-left: -20px;
}
#kimini-main .kimini-button {
  height: 56px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #kimini-main .kimini-qualification {
    width: 100%;
  }
}/*# sourceMappingURL=MyPageKimini.css.map */