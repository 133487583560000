#auth-login .auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#auth-login .auth-options h1 {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  #auth-login .auth-options {
    margin-top: 100px;
    width: 452px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  #auth-login .auth-options {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
  }
}
#auth-login .auth-options .auth-aka-logo {
  cursor: pointer;
  display: block;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 479px) {
  #auth-login .auth-options .auth-aka-logo {
    margin-top: 50px;
  }
}
#auth-login .auth-options .auth-option-field {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px;
}
@media (max-width: 479px) {
  #auth-login .auth-options .auth-option-field {
    width: 328px;
  }
}
#auth-login .auth-button {
  width: 452px;
  height: 64px;
  color: #FFFFFF;
  font-size: 24px;
  border-radius: 8px;
}
@media (max-width: 479px) {
  #auth-login .auth-button {
    width: 328px;
  }
}
#auth-login .sub-button {
  margin-top: 16px;
  justify-content: center;
  display: flex;
}
#auth-login .sub-button .join-and-find {
  font-size: 18px;
  font-weight: normal;
  color: #7E8288;
  border-color: #7E8288;
}
#auth-login .sign-up-text {
  font-size: 16px;
  color: #7E8288;
  margin-bottom: 12px;
}
#auth-login .sign-up {
  color: #28292B;
  border: 1px solid #C0C0C0;
}
@media (min-width: 768px) {
  #auth-login .sign-up {
    width: 452px;
    height: 64px;
    margin-bottom: 100px;
    font-size: 22px;
    border-radius: 8px;
  }
}
@media (max-width: 479px) {
  #auth-login .sign-up {
    margin-bottom: 80px;
  }
}
#auth-login .sign-up:hover {
  background: #F5F5F5;
}/*# sourceMappingURL=AuthLogin.css.map */